import React, { useEffect, useState } from 'react';
import { Button, Group, NumberInput, Container, Title, Table, Select } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify'; // Import React-Toastify

// Ensure you import the required CSS for React-Toastify
import 'react-toastify/dist/ReactToastify.css';

interface Sale {
  id?: number;
  customer_id: number;
  price: number;
  weight: number;
  date: string;
  customer?: Customer; // Store the whole customer object
}

interface Customer {
  id: number;
  name: string;
}

const Sales: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [sales, setSales] = useState<Sale[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null); // Changed to string | null
  const [price, setPrice] = useState<number | undefined>();
  const [weight, setWeight] = useState<number | undefined>();
  const [date, setDate] = useState<Date | null>(null);
  const [cookies] = useCookies(['token']);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch customers from the API when the component is mounted
  useEffect(() => {
    axios
      .get(`${apiUrl}/customer`, {
        headers: { Authorization: 'Bearer ' + cookies.token },
      })
      .then((response) => {
        if (response.data.status === 'success' && Array.isArray(response.data.data)) {
          setCustomers(response.data.data);
        } else {
          setCustomers([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching customers:', error);
        toast.error('Failed to fetch customers.');
      });
  }, [apiUrl, cookies.token]);

  // Fetch sales when the component mounts
  useEffect(() => {
    axios
      .get(`${apiUrl}/sales/`, {
        headers: { Authorization: 'Bearer ' + cookies.token },
      })
      .then((response) => {
        if (response.data.status === 'success' && Array.isArray(response.data.data)) {
          // Enrich sales data with customer objects
          const enrichedSales = response.data.data.map((sale: Sale) => {
            const customer = customers.find(cust => cust.id === sale.customer_id);
            return {
              ...sale,
              customer: customer || undefined, // Enrich sales with the customer object
            };
          });
          setSales(enrichedSales);
        } else {
          setSales([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching sales:', error);
        toast.error('Failed to fetch sales.');
      });
  }, [apiUrl, cookies.token, customers]);

  // Filter sales based on the selected customer
  const filteredSales = selectedCustomer === null 
    ? sales // If 'All Customers' is selected, show all sales
    : sales.filter((sale) => sale.customer_id === parseInt(selectedCustomer)); // Otherwise, filter by selected customer

  const handleSubmit = () => {
    if (selectedCustomer && price && weight && date) {
      const sale: Sale = {
        id: 0, // Adding dummy id (if not provided by the API)
        customer_id: parseInt(selectedCustomer),
        price,
        weight,
        date: date ? date.toLocaleDateString('en-CA') : '',
        customer: undefined, // Add a customer object if needed, depending on your API response
      };

      axios
        .post(`${apiUrl}/sales`, sale, {
          headers: { Authorization: 'Bearer ' + cookies.token },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            toast.success('Sale has been assigned successfully.');
            // Immediately update the sales state to include the new sale
            const newSale = { ...sale, id: response.data.data.id, customer: customers.find(cust => cust.id === sale.customer_id) };
            setSales((prevSales) => [...prevSales, newSale]);
            // Reset form after successful submission
            setSelectedCustomer(null);
            setPrice(undefined);
            setWeight(undefined);
            setDate(null);
          } else {
            toast.error('Failed to assign sale.');
          }
        })
        .catch((error) => {
          console.error('Error assigning sale:', error);
          toast.error('An error occurred while assigning the sale.');
        });
    } else {
      toast.warning('Please fill in all required fields.');
    }
  };

  const handleDelete = (saleId: number) => {
    axios
      .delete(`${apiUrl}/sales/${saleId}`, {
        headers: { Authorization: 'Bearer ' + cookies.token },
      })
      .then((response) => {
        if (response.data.status === 'success') {
          toast.success('Sale deleted successfully.');
          setSales((prevSales) => prevSales.filter(sale => sale.id !== saleId));
        } else {
          toast.error('Failed to delete sale.');
        }
      })
      .catch((error) => {
        console.error('Error deleting sale:', error);
        toast.error('An error occurred while deleting the sale.');
      });
  };

  const handleUpdate = (saleId: number, updatedSale: Sale) => {
    axios
      .put(`${apiUrl}/sales/${saleId}`, updatedSale, {
        headers: { Authorization: 'Bearer ' + cookies.token },
      })
      .then((response) => {
        if (response.data.status === 'success') {
          toast.success('Sale updated successfully.');
          setSales((prevSales) =>
            prevSales.map((sale) =>
              sale.id === saleId ? { ...sale, ...updatedSale } : sale
            )
          );
        } else {
          toast.error('Failed to update sale.');
        }
      })
      .catch((error) => {
        console.error('Error updating sale:', error);
        toast.error('An error occurred while updating the sale.');
      });
  };

  return (
    <Container>
      <Title>Assign Sale</Title>
      <Group>
        <Select
          placeholder="Select a customer"
          value={selectedCustomer || ''}
          onChange={(value) => setSelectedCustomer(value)}
          data={[
            { value: '', label: 'All Customers' }, // Show All Customers option
            ...customers.map((customer) => ({
              value: String(customer.id),
              label: customer.name,
            })),
          ]}
        />
        <NumberInput
          placeholder="Price"
          value={price}
          onChange={setPrice}
        />
        <NumberInput
          placeholder="Weight (kg)"
          value={weight}
          onChange={setWeight}
        />
        <DatePicker
          placeholder="Sale Date"
          value={date}
          onChange={setDate}
        />
        <Button onClick={handleSubmit}>Assign Sale</Button>
      </Group>

      <Title>Sales Data</Title>
      <Table>
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Price</th>
            <th>Weight (kg)</th>
            <th>Sale Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredSales.map((sale) => (
            <tr key={sale.id}>
              <td>{sale.customer ? sale.customer.name : 'Unknown Customer'}</td>
              <td>{sale.price}</td>
              <td>{sale.weight}</td>
              <td>{new Date(sale.date).toLocaleDateString()}</td>
              <td>
                <Button color="red" onClick={() => handleDelete(sale.id!)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Sales;
