import { Container, Divider, Group, Text, Title, Grid, Image, TextInput, } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import http from '../axios/index';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import moment from "moment";
import background from "../asset/images/header-logo.png";


export default function InvoicePrint() {


  let { id } = useParams();
  console.log(id);


  const [cookies] = useCookies(['user', 'token']);
  const user = cookies.user;

  const [name] = useState("")



  // const [invoiceId, setInvoiceId] = useState("");

  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [invoiceNote, setInvoiceNote] = useState("");

  const [subTotal, setSubTotal] = useState(0);

  const [invoiceGst, setInvoiceGst] = useState(0);

  const [furtherTax, setFurtherTax] = useState(0);

  const [invoiceTotal, setInvoiceTotal] = useState(0);

  const [date, setDate] = useState("");



    // Customers Data
  const [customerName, setCustomerName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerStatus, setCustomerStatus] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerNtn, setCustomerNtn] = useState("");
  const [customerStrn, setCustomerStrn] = useState("");
  const [customerCnic, setCustomerCnic] = useState("");
  const [term, setTerm] = useState("");

    // Items 
  const [items, setItems] = useState<
    Array<{
        product: any,
        quantity: string,
        unit_price: string,
        unit: string,
        total: string
    }>
  >([]);

  function convertNumberToWords (number: any) {
    const first = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
    const tens = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
    const mad = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
    let word = 'Only';

    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number%(100*Math.pow(1000,i));
      if (Math.floor(tempNumber/Math.pow(1000,i)) !== 0) {
        if (Math.floor(tempNumber/Math.pow(1000,i)) < 20) {
          word = first[Math.floor(tempNumber/Math.pow(1000,i))] + mad[i] + ' ' + word;
        } else {
          word = tens[Math.floor(tempNumber/(10*Math.pow(1000,i)))] + '-' + first[Math.floor(tempNumber/Math.pow(1000,i))%10] + mad[i] + ' ' + word;
        }
      }

      tempNumber = number%(Math.pow(1000,i+1));
      if (Math.floor(tempNumber/(100*Math.pow(1000,i))) !== 0) word = first[Math.floor(tempNumber/(100*Math.pow(1000,i)))] + 'Hunderd ' + word;
    }
      return word;
}


 // On Page Load
  useEffect(()=>{
    getInvoice();
  },[]);


  function getInvoice() {
    http.get(`get-invoice/${id}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.entries.map((item: any) => setItems((curData) => [...curData, { product: item.item, quantity: item.quantity, unit_price: item.unit_price, unit: item.unit.name, total: item.total}]));
      // setInvoiceId(response.data.month + response.data.year + '-' + response.data.counter);
      setInvoiceNumber(response.data.invoice_number);
      setInvoiceNote(response.data.note);
      setSubTotal(response.data.sub_total);
      setInvoiceGst(response.data.excise_duty);
      setFurtherTax(response.data.extra_charges);
      setInvoiceTotal(response.data.total);
      setCustomerName(response.data.customer.name);
      setBusinessName(response.data.customer.business_name);
      setCustomerAddress(response.data.customer.address);
      setCustomerPhone(response.data.customer.phone);
      setCustomerNtn(response.data.customer.ntn_number);
      setCustomerStrn(response.data.customer.strn_number);
      setCustomerCnic(response.data.customer.cnic);
      setDate(response.data.date);
      setTerm(response.data.term ? 'Cash' : 'Credit');
      setCustomerStatus(response.data.customer.status ? 'Registerd' : 'Non-Registerd');
    });
  }

  const entries = items.map((item, index) => (
    <tr key={index}>
      <td><strong>{item.product.code}</strong></td>
      <td><strong>{item.product.name}</strong></td>
      <td><strong>{item.quantity}</strong></td>
      <td><strong>{item.unit}</strong></td>
      <td><strong>{item.unit_price}</strong></td>
      <td><strong>{item.total}</strong></td>
    </tr>
  ));


  return (
    <>
      <div className='printablediv' style={{backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "40%"}}>
        <Container my="md">
          <Title fz={25} className="sales-tax-title">Sales Tax <br/>Invoice</Title>
          <Grid>
            <Grid.Col span={7}>
              <Group className="col-group">
                <Image
                    radius="md"
                    src={`${process.env.REACT_APP_WEB_URL}${user.branch.company.logo ? user.branch.company.logo : ''}`}
                    alt={name}
                    height={110}
                    width={120}
                    mt={20}
                  />
                <div className="salamat-title-div">
                  <Text fz={25} fw={700} mt={25}>{user.branch.company.name}</Text>
                  <Text>{user.branch.address}</Text>
                  <Text>{user.branch.contacts.map(function({value, id, type}: any){
                      return type === 'phone' ? <span key={ id }>{value}, </span> : '';
                    })}</Text>
                  <Text><strong>STRN NO:</strong> {user.branch.company.strn_number}</Text>  
                  <Text><strong>NTN No:</strong> {user.branch.company.ntn_number}</Text>

                </div>
              </Group>
            </Grid.Col>
            <Grid.Col span={5} mt={10}>
            <Text><strong>Date:</strong> {moment(date).format("DD-MM-YYYY")}</Text>
              <Text><strong>Invoice No:</strong> {invoiceNumber}</Text>
              <Text mt={40}><strong>Term:</strong> {term}</Text>
              <Text mt={5}><strong>Status:</strong> {customerStatus}</Text>

            </Grid.Col>
          </Grid>
          <Divider size="lg" my="md" />
          <Grid>
            <Grid.Col span={7}>
              <Group>
                <div className="customer-div">
                  <Text fz={25} fw={700}>Bill To</Text>
                  <strong>Customer Name:</strong> <Text style={{borderBottom: '1.5px solid #000', display: 'inline-block'}}>{customerName} </Text>
                  <Text mt={5}><strong>Business Name:</strong> {businessName}</Text>
                  <Text mt={5}><strong>Contact:</strong> {customerPhone}</Text>
                  <Text mt={5}><strong>Address:</strong> {customerAddress}</Text>
                </div>
              </Group>
            </Grid.Col>
            <Grid.Col span={5} mt={55}>
              <Text mt={5}><strong>NTN No:</strong> {customerNtn}</Text>
              <Text mt={5}><strong>STRN No:</strong> {customerStrn}</Text>
              <Text mt={5}><strong>CNIC No:</strong> {customerCnic}</Text>
            </Grid.Col>
          </Grid>
          <Divider size="lg" my="md" />
          <table className="invoice-table">
            <thead>
              <tr>
                <th><Text fz={17} fw={875}>Product ID</Text></th>
                <th><Text fz={17} fw={875}>Description</Text></th>
                <th><Text fz={17} fw={875}>Quantity</Text></th>
                <th><Text fz={17} fw={875}>Unit</Text></th>
                <th><Text fz={17} fw={875}>Unit Price</Text></th>
                <th><Text fz={17} fw={875}>Line Total</Text></th>
              </tr>
            </thead>
            <tbody>
              {entries}
              <tr>
                <td rowSpan={4} colSpan={4} className="transparent-bg no-border">
                  <Text fw={600} fz="sm">NOTES:</Text>
                  <Text className="note-text-css" p="sm">{invoiceNote}</Text>
                </td>
                <td className="transparent-bg no-border"><strong>Sub Balance</strong></td>
                <td><strong>{subTotal}</strong></td>
              </tr>
              <tr>
                <td className="transparent-bg no-border"><strong>GST 18.0%</strong></td>
                <td><strong>{invoiceGst}</strong></td>
              </tr>
              <tr>
                <td className="transparent-bg no-border"><strong>Further Tax 3.0%</strong></td>
                <td><strong>{furtherTax}</strong></td>
              </tr>
              <tr>
                <td className="transparent-bg no-border"><strong>TOTAL</strong></td>
                <td><Text fz={17} fw={875}>{invoiceTotal}</Text></td>
              </tr>
            </tbody>
          </table>
          <Text style={{borderBottom: '1px solid #000', display: 'inline-block'}} mt="xl"><strong>{invoiceTotal ? convertNumberToWords(invoiceTotal) : ''}</strong></Text>
          <Text className="text-thanku" fz={18} fw={500} my="lg" p="xs">Thank You For Your Business!</Text>
        </Container>
      </div>
      
    </>
  );
}