import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Notification } from '@mantine/core';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useForm } from '@mantine/form';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import Moment from 'react-moment';

export default function Abilities() {

  const [cookies, setCookie] = useCookies(['token', 'user', 'abilities']);

  const[search, setSearch] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Abilities Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);


  // On Page Load
  useEffect(()=>{
    getAbilities(1);
  },[search]);

  // Ability form
  const form = useForm({
    initialValues: {
      id: '',
      name: '',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Ability field required' : null),
    },
  });

  // Functions

  // Get Abilities
  function getAbilities(page: any) {
    setFetching(true);
    http.get(`abilities/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Create Ability Function
  const createAbility = () => {
    form.reset();
    form.values.name = '';
    setTitle('Create New Ability!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Ability
  function addAbility() {
    setButtonLoading(true);
    http.post(`abilities`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      form.reset();
      getAbilities(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Ability created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          form.setFieldError('name', error.response.data.errors.name.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Edit Ability Function
  const editAbility = ({id, name}: any) => {
    setTitle('Edit Ability!');
    setButton('Update');
    form.reset();
    form.setFieldValue('id', id);
    form.setFieldValue('name', name);
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Update Ability
  function updateAbility() {
    setButtonLoading(true);
    http.put(`abilities/${form.values.id}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      form.reset();
      setButtonLoading(false);
      setOpenedDialog(false);
      getAbilities(1);
      setNotificationType(true);
      setNotification('Ability updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotificationType(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          form.setFieldError('name', error.response.data.errors.name.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Select Ability to delete
  const deleteAbility = ({id}: any) => {
    form.values.id = id;
    setOpenedDeleteDialog(true);
  }

  // Delete Ability
  function deleteSelectedAbility() {
    setButtonLoading(true);
    http.delete('abilities/' + form.values.id, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      console.log(response);
      form.reset();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      getAbilities(1);
      setNotificationType(true);
      setNotification('Ability deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      form.reset();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Abilities' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Abilities</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createAbility()} color="green" className="mt-15" mr={10}>Create New Ability</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Ability..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div> 
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        records={data}
        minHeight={90}
        fetching={fetching}
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          {
            accessor: 'Last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            textAlignment: 'center',
            // this column has custom cell data rendering
            render: ({ id, name }) => (
              <>
                <Button onClick={() => editAbility({id, name})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteAbility({id})}>
                  Delete
                </Button>
              </>
            ),
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addAbility() : updateAbility())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Ability Name" withAsterisk {...form.getInputProps('name')} />
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)} loading={buttonLoading}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedAbility()}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getAbilities(e)} className="justify-content-center" mt={15} />
    </>
  );
}