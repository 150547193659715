import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, NumberInput, Pagination, Modal, TextInput, Select, Notification } from '@mantine/core';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useForm } from '@mantine/form';
import Moment from 'react-moment';

export default function Branches() {

  const [cookies] = useCookies(['user', 'token', 'abilities']);

  const[search, setSearch] = useState("");

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Branches Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);

  // On Page Load
  useEffect(()=>{
    getBranches(1);
  },[search]);

  // Branch form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      name: '',
      code: '',
      address: '',
      phone: '',
      phone_contact_id: '',
      email: '',
      email_contact_id: '',
      type: 'manufacture',
      company_id: cookies.user.company_id,
      status: ''

    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Branch Name field required' : null),
      code: (value) => (value.length < 2 ? 'Branch Code field required' : null),
    },
  });

  // Functions

  // Reset Form
  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.uuid = '';
    form.values.name = '';
    form.values.code = '';
    form.values.address = '';
    form.values.phone_contact_id = '';
    form.values.phone = '';
    form.values.email_contact_id = '';
    form.values.email ='';
    form.values.type = 'manufacture';
    form.values.company_id = cookies.user.company_id;
    form.values.status = '1';
  }

  // Get Branches
  function getBranches(page: any) {
    setFetching(true);
    http.get(`branches/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Create Branch
  const createBranch = () => {
    resetForm();
    form.values.company_id = cookies.user.company_id;
    setTitle('Create New Branch!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Branch
  function addBranch() {
    setButtonLoading(true);
    http.post('branches', form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getBranches(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Branch created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotification(error.response.data.message);
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    }); 
  }

  // Edit Branch Function
  const editBranch = ({id, uuid, name, code, address, type, company_id, status, contacts}: any) => {
    let phone: any = contacts.find( ({ type }: any) => type === 'phone' );
    let email: any = contacts.find( ({ type }: any) => type === 'email' );
    setTitle('Edit Branch!');
    setButton('Update');
    resetForm();
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('name', name);
    form.setFieldValue('code', code);
    form.setFieldValue('address', address);
    form.setFieldValue('phone_contact_id', (phone ? phone.id : ''));
    form.setFieldValue('phone', (phone ? phone.value : ''));
    form.setFieldValue('email_contact_id', (email ? email.id : ''));
    form.setFieldValue('email', (email ? email.value : ''));
    form.setFieldValue('type', type);
    form.setFieldValue('company_id', company_id);
    form.setFieldValue('status', (status ? '1': '0'));
    setShowNotification(false);
    setOpenedDialog(true);
  }
  
  // Update Branch
  function updateBranch() {
    setButtonLoading(true);
    http.put(`branches/${form.values.uuid}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDialog(false);
      getBranches(1);
      setNotificationType(true);
      setNotification('Branch updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotificationType(false);
      setNotification(error.response.data.message);
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    }); 
  }

  // Select Branch to delete
  const deleteBranch = ({uuid}: any) => {
    form.values.uuid = uuid;
    setOpenedDeleteDialog(true);
  }

  // Delete Branch
  function deleteSelectedBranch() {
    setButtonLoading(true);
    http.delete('branches/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      getBranches(1);
      setNotificationType(true);
      setNotification('Branch deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }); 
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Branches' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Branches</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createBranch()} color="green" className="mt-15" mr={10}>Create New Branch</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Branch..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>  

      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        // provide data
        records={data}
        minHeight={90}
        fetching={fetching}
        // define columns
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          { accessor: 'code' },
          { accessor: 'address' },
          { 
            accessor: 'type',
            render: ({ type  }: any) => (
              <>{type.charAt(0).toUpperCase() + type.slice(1)}</>
            )
          },
          {
            accessor: 'status',
            render: ({ status  }) => (
              <>{status == '1' ? 'Enable' : 'Disable'}</>
            )
          },
          {
            accessor: 'last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            // this column has custom cell data rendering
            render: ({ id, uuid, name, code, address, type, company_id, status, contacts }: any) => (
              <>
                <Button onClick={() => editBranch({id, uuid, name, code, address, type, company_id, status, contacts})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteBranch({uuid})}>
                  Delete
                </Button>
              </>
            ),
            textAlignment: 'center',
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addBranch() : updateBranch())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Enter Branch Name" withAsterisk {...form.getInputProps('name')} />
          <TextInput mt="sm" label="Code" placeholder="Enter Branch Code" withAsterisk {...form.getInputProps('code')} />
          <TextInput mt="sm" label="Address" placeholder="Enter Address" {...form.getInputProps('address')} />
          <TextInput mt="sm" label="Email" placeholder="Enter Email" {...form.getInputProps('email')} />
          <TextInput mt="sm" label="Phone" placeholder="Enter Phone" {...form.getInputProps('phone')} />
          <Select
            mt="sm"
            label="Type"
            placeholder="Select Type"
            data={[
              { value: 'manufacture', label: 'Manufacture' },
              { value: 'retailer', label: 'Retailer' },
            ]}
            withAsterisk
            {...form.getInputProps('type')}
          />
          <Select
            mt="sm"
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Enable' },
              { value: '0', label: 'Disable' },
            ]}
            withAsterisk
            {...form.getInputProps('status')}
          />
          <div className="text-right">
            <Button color="red" type="submit" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedBranch()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getBranches(e)} className="justify-content-center" mt={15} />
    </>
  );
}