import React, { useState, useEffect } from 'react';
import { Container, Radio, Select, Modal, TextInput, createStyles, Card, Group, ActionIcon, Box, Text, Button, NumberInput, SimpleGrid, Grid, Image, Notification, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { randomId } from '@mantine/hooks';
import { IconTrash, IconCheck, IconX, IconUser } from '@tabler/icons';
import { useForm } from '@mantine/form';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';


export default function PurchaseInvoice() {

  const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  }));

  const { classes } = useStyles();

  const [cookies] = useCookies(['user', 'token', 'abilities']);
  const user = cookies.user;

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Commission Agents with label and value
  const [agentsData, setAgentsData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Items with label and value
  const [itemsData, setItemsData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Vendors with label and value
  const [vendorsData, setVendorsData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Set Invoice Id
  const [invoiceId, setInvoiceId] = useState("");

  // On Page Load
  useEffect(()=>{
    getCommissionAgents();
    getItems();
    getVendors();
    getInvoiceId();
  },[]);

  const form = useForm({
    initialValues: {
      invoiceId: invoiceId,
      created_at: new Date(),
      term: '1',
      type: 'purchase',
      agent_id: '',
      vehicale_number: '',
      po_number: '',
      customer_id: '',
      sub_total: 0,
      extra_charges: 0,
      total_discount: 0,
      total: 0,
      excise_duty: 0,
      discount: 0,
      freight_charges: 0,
      total_due: 0,
      cash_received: 0,
      balance: 0,
      note: '',
      products: [
        {
          item: null,
          unit_price: 0,
          quantity: 0,
          toggle: true,
          disc_perc: 0,
          disc_pkr: 0,
          discount: 0,
          total: 0,
          key: randomId(),
        }
      ]
    },
  });

  // Vendor form
  const vendorForm = useForm({
    initialValues: {
      name: '',
      code: '',
      email: '',
      phone: '',
      address: '',
      ntn_number: '',
      strn_number: '',
      company_id: cookies.user.company_id,
      balance: 0,
      status: '1',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
      // balance: (value) => (value ? null : 'Please Add Balance'),
    },
  });

  // Functions

  function removeRow(index: number) {
    if (form.values.products.length > 1) {
      form.removeListItem('products', index);
    }
  }

  // Get Commission Agents
  function getCommissionAgents() {
    http.get('commission-agent', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setAgentsData((curData) => [...curData, { value: item.id, label: item.name }]));
    });
  }

  // Get Items
  function getItems() {
    http.get('item', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setItemsData((curData) => [...curData, { value: item, label: item.name }]));
    });
  }

  // Get Vendors
  function getVendors() {
    http.get('vendor', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setVendorsData((curData) => [...curData, { value: item.id, label: item.name }]));
    });
  }

  // Add Vednor
  function addVendor() {
    setButtonLoading(true);
    http.post('vendor', vendorForm.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      vendorForm.reset();
      getVendors();
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Vendor created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.strn_number) {
          vendorForm.setFieldError('strn_number', error.response.data.errors.strn_number.shift());
        }
        if (error.response.data.errors.ntn_number) {
          vendorForm.setFieldError('ntn_number', error.response.data.errors.ntn_number.shift());
        }
        if (error.response.data.errors.phone) {
          vendorForm.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          vendorForm.setFieldError('email', error.response.data.errors.email.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  function getInvoiceId() {
    http.get('invoice-id', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setInvoiceId(response.data.data);
    });
  }

  function changeItem(item: any, product: any, index: number) {
    let price = item.price ? item.price : 0;
    let quantity = product.quantity ? product.quantity : 1;
    let discount = 0;
    if (product.discount) {
      discount = calRowDiscount(price, quantity, product.discount);
    }
    form.setFieldValue('products.'+index+'.item', item);
    form.setFieldValue('products.'+index+'.unit_price', price);
    form.setFieldValue('products.'+index+'.quantity', 1);
    calRowTotal(price, quantity, discount, index);
  }

  function changePrice(price: any, product: any, index: number) {
    price = price ? price : 0;
    let quantity = product.quantity ? product.quantity : 1;
    let discount = 0;
    if (product.discount) {
      discount = calRowDiscount(price, quantity, product.discount);
    }
    form.setFieldValue('products.'+index+'.unit_price', price);
    calRowTotal(price, quantity, discount, index);
  }

  function changeQuantity(quantity: any, product: any, index: number) {
    quantity = quantity ? quantity : 0;
    let price = product.unit_price ? product.unit_price : 0;
    let discount = 0;
    if (product.discount) {
      discount = calRowDiscount(price, quantity, product.discount);
    }
    form.setFieldValue('products.'+index+'.quantity', quantity);
    calRowTotal(price, quantity, discount, index);
  }

  function changeDiscount(discount: any, product: any, index: number) {
    let price = product.unit_price ? product.unit_price : 0;
    let quantity = product.quantity ? product.quantity : 0;
    let calc_discount = 0;
    if (discount) {
      calc_discount = calRowDiscount(price, quantity, discount);
    }
    form.setFieldValue('products.'+index+'.discount', discount);
    calRowTotal(price, quantity, calc_discount, index);
  }

  function calRowDiscount(price: any, quantity: any, discount: any) {
    let total = price * quantity;
    let calc_discount = (total / 100) * discount;
    return calc_discount;
  }

  function calRowTotal(price: any, quantity: any, discount: any, index: number) {
    form.setFieldValue('products.'+index+'.total', (parseInt(price) * parseInt(quantity) - parseFloat(discount)));
    calSubTotal();
  }


  function calSubTotal() {
    let sub_total = 0;
    form.values.products.forEach(entry => {
      (sub_total += entry.total);
    });
    form.setFieldValue('sub_total', sub_total);
  }

  function changeExciseDuty(e: any) {
    let sub_total = form.values.sub_total ? form.values.sub_total : 0;
    let cash_received = form.values.cash_received ? form.values.cash_received : 0;
    let excise_duty = e ? e : 0;
    let extra_charges = form.values.extra_charges ? form.values.extra_charges : 0;
    let total = sub_total + excise_duty + extra_charges;
    let calc_discount = 0;
    if (form.values.total_discount) {
      calc_discount = (total / 100) * form.values.total_discount; 
    }
    form.setFieldValue('excise_duty', excise_duty);
    calTotal(sub_total, excise_duty, extra_charges, calc_discount, cash_received);
  }

  function changeExtraDuty(e: any) {
    let sub_total = form.values.sub_total ? form.values.sub_total : 0;
    let cash_received = form.values.cash_received ? form.values.cash_received : 0;
    let extra_charges = e ? e : 0;
    let excise_duty = form.values.excise_duty ? form.values.excise_duty : 0;
    let total = sub_total + excise_duty + extra_charges;
    let calc_discount = 0;
    if (form.values.total_discount) {
      calc_discount = (total / 100) * form.values.total_discount; 
    }
    form.setFieldValue('extra_charges', extra_charges);
    calTotal(sub_total, excise_duty, extra_charges, calc_discount, cash_received);
  }

  function changeTotalDiscount(e: any) {
    let sub_total = form.values.sub_total ? form.values.sub_total : 0;
    let cash_received = form.values.cash_received ? form.values.cash_received : 0;
    let extra_charges = form.values.extra_charges ? form.values.extra_charges : 0;
    let excise_duty = form.values.excise_duty ? form.values.excise_duty : 0;
    let total = sub_total + excise_duty + extra_charges;
    let discount = e ? e : 0;
    let calc_discount = 0;
    if (discount) {
      calc_discount = (total / 100) * discount; 
    }
    form.setFieldValue('total_discount', discount);
    calTotal(sub_total, excise_duty, extra_charges, calc_discount, cash_received);
  }

  function changeCashReceived(e: any) {
    let sub_total = form.values.sub_total ? form.values.sub_total : 0;
    let extra_charges = form.values.extra_charges ? form.values.extra_charges : 0;
    let excise_duty = form.values.excise_duty ? form.values.excise_duty : 0;
    let cash_received = e ? e : 0;
    let total = sub_total + excise_duty + extra_charges;
    let calc_discount = 0;
    if (form.values.total_discount) {
      calc_discount = (total / 100) * form.values.total_discount; 
    }
    form.setFieldValue('cash_received', cash_received);
    calTotal(sub_total, excise_duty, extra_charges, calc_discount, cash_received);
  }

  function calTotal(sub_total: any, excise_duty: any, extra_charges: any, total_discount: any, cash_received: any) {
    let total = sub_total + excise_duty + extra_charges - total_discount;
    form.setFieldValue('total', total);
    form.setFieldValue('balance', total - cash_received);
  }




  // Vendor Form Data
  const [date, setDate] = useState(new Date())
  const [name, setName] = useState("")
  const [code, setCode] = useState(0)
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState(0)
  const [ntn, setNtn] = useState(0)
  const [strn, setStrn] = useState(0)
  const [price, setPrice] = useState("")
  const [balance, setBalance] = useState("")
  const [company, setCompany] = useState("")
  const [comission, setComission] = useState("")
  const [vehicale, setVehicale] = useState("")
  const [po, setPo] = useState("")
  const [vendor, setVendor] = useState("")
  const [status, setStatus] = useState("1")
  const [value, setValue] = useState('cash');
  const [duty, setDuty] = useState(0);
  const [extraDuty, setExtraDuty] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [note, setNote] = useState('');


  // Create Vendor Function
  const createVendor = () => {
    setTitle('Create New Vendor!');
    setName("");
    setCode(0);
    setAddress("");
    setEmail("");
    setPhone(0);
    setNtn(0);
    setStrn(0);
    setPrice("");
    setBalance("");
    setCompany("");
    setStatus("1");
    setOpenedDialog(true);
  }

  const fields = form.values.products.map((product, index) => (
    <Group key={product.key} mt="xs">
      <Select
        placeholder="Select Item"
        data={itemsData}
        sx={{ flex: 1 }}
        nothingFound="Data Not Found"
        clearable
        searchable
        onChange={(e) => changeItem(e, product, index)} 
      />
      <NumberInput
        placeholder="Unit Price"
        withAsterisk
        sx={{ flex: 1 }}
        min={1}
        hideControls
        {...form.getInputProps('products.'+index+'.unit_price')}
        onChange={(e) => changePrice(e, product, index)}
      />
      <NumberInput
        placeholder="Quantity"
        withAsterisk
        sx={{ flex: 1 }}
        min={1}
        hideControls
        {...form.getInputProps('products.'+index+'.quantity')}
        onChange={(e) => changeQuantity(e, product, index)}
      />
      <NumberInput
        placeholder="0.0"
        withAsterisk
        sx={{ flex: 1 }}
        min={0}
        {...form.getInputProps('products.'+index+'.discount')}
        onChange={(e) => changeDiscount(e, product, index)}
      />
      <NumberInput
        placeholder="0"
        sx={{ flex: 1 }}
        {...form.getInputProps('products.'+index+'.total')}
        disabled
      />
      <ActionIcon color="red" onClick={() =>  removeRow(index)}>
        <IconTrash size={16} />
      </ActionIcon>
    </Group>
  ));

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Purchase Invoice' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <Card withBorder radius="md" className={classes.card} mt={15}>
      <Container my="md">
        <SimpleGrid cols={2}>
          <Card withBorder radius="md">
            <h2 className="mt-0">Purchase Invoice</h2>
            <div style={{display: 'flex'}}>
              <div className="d-inline-block">
                <Image
                  radius="md"
                  src={`${process.env.REACT_APP_WEB_URL}${user.branch.company.logo ? user.branch.company.logo : ''}`}
                  alt={name}
                  height={110}
                  width={120}
                />
              </div>
              <div className="d-inline-block ml-20">
                <h2 className="mb-0 mt-0">{user.branch.company.name}</h2>
                <p className="mt-0 mb-0">{user.branch.address}</p>
                <ul className="contacts mt-0">
                  {user.branch.contacts.map(function({value, id, type}: any){
                    return type === 'phone' ? <li key={ id }>{value}</li> : '';
                  })}
                </ul>
              </div>
            </div>
          </Card>
          <Card withBorder radius="md">
            <h4>Invoice # {invoiceId}</h4>
            <DatePicker placeholder="Pick date" label="Created" withAsterisk {...form.getInputProps('created_at')} />
            <Radio.Group
              orientation="vertical"
              withAsterisk
              {...form.getInputProps('term')}
            >
              <Radio value="1" label="Cash" mb={-10}/>
              <Radio value="0" label="Credit" />
            </Radio.Group>
            <Select
              label="Commission Agent"
              placeholder="Select Commission Agent"
              data={agentsData}
              {...form.getInputProps('agent_id')}
            />
            <TextInput mt={10}
              placeholder="Vehicale Number"
              label=" Enter Vehicale Number:"
              withAsterisk
              {...form.getInputProps('vehicale_number')}
            />
            <TextInput mt={10}
              placeholder="Customer PO:"
              label="Enter Customer PO"
              withAsterisk
              {...form.getInputProps('po_number')}
            />
          </Card>
        </SimpleGrid>
        <Select ml={5}
          style={{width: 300, display: 'inline-block'}}
          placeholder="Vendors"
          label="Vendors"
          withAsterisk
          nothingFound="Data Not Found"
          clearable
          icon={<IconUser size="1rem" />}
          data={vendorsData}
          searchable
          {...form.getInputProps('vendor')}
        />
        <Button my={10} ml={10} onClick={() => createVendor()}>Add New Vendor</Button>
      <div className="w-100">
        <Card withBorder radius="md" mt={15} className="overflow">
          <Box mx="auto">
            {fields.length > 0 ? (
              <Group mb="xs">
                <Text weight={500} size="sm" sx={{ flex: 1 }}>
                  Item
                </Text>
                <Text weight={500} size="sm" sx={{ flex: 1 }}>
                  Unit Price
                </Text>
                <Text weight={500} size="sm" sx={{ flex: 1 }}>
                  Quantity
                </Text>
                <Text weight={500} size="sm" sx={{ flex: 1 }} mr={17}>
                  Disc(%pkr)
                </Text>
                <Text weight={800} size="sm" sx={{ flex: 1 }} mr={30}>
                  Total
                </Text>
              </Group>
            ) : (
              <Text color="dimmed" align="left">
                No Row Here...
              </Text>
            )}

            {fields}

            <Group position="left" mt="md">
              <Button
                onClick={() =>
                  form.insertListItem('products', { item: null, unit_price: 0, quantity: 0, toggle: true, disc_perc: 0, disc_pkr: 0, discount: 0, total: 0, key: randomId()})
                }
              >
                Add Row
              </Button>
            </Group>
          </Box>
        </Card>
      </div>
      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={vendorForm.onSubmit((values) => addVendor())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Enter Name" withAsterisk {...vendorForm.getInputProps('name')} />
          <TextInput mt="sm" placeholder="Enter Code" label="Code" {...vendorForm.getInputProps('code')} />
          <TextInput mt="sm" label="Email" placeholder="Enter Email" {...vendorForm.getInputProps('email')} />
          <TextInput mt="sm" placeholder="Enter Phone" label="Phone" {...vendorForm.getInputProps('phone')} />
          <TextInput mt="sm" label="Address" placeholder="Enter Address" {...vendorForm.getInputProps('address')} />
          <TextInput mt="sm" placeholder="Enter NTN Number" label="NTN" {...vendorForm.getInputProps('ntn_number')} />
          <TextInput mt="sm" placeholder="Enter STRN Number" label="STRN" {...vendorForm.getInputProps('strn_number')} />
          {/*<TextInput mt="sm" label="Price List" placeholder="Enter Price List" {...vendorForm.getInputProps('price')} />*/}
          <Select
            mt={12}
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Enable' },
              { value: '0', label: 'Disable' },
            ]}
            {...form.getInputProps('status')}
          />
          <div className="text-right">
            <Button color="red" type="button" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              Save
            </Button>
          </div>
        </form> 
      </Modal>
    <Grid grow>
      <Grid.Col span={6}>
        <Textarea mt={15}
          placeholder="Enter Note"
          label="Vendor Note"
          withAsterisk
          {...form.getInputProps('note')}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <div className="text-right">
          <form>
            <label>Sub Total
              <NumberInput ml={10} mt={5} style={{width: 180, display: 'inline-block'}} disabled placeholder="0" {...form.getInputProps('sub_total')}/>
            </label>
          </form>
          <form>
            <label>Excise Duty/Extra Tax%
              <NumberInput ml={10} mt={5} style={{width: 180, display: 'inline-block'}} hideControls placeholder="0.00" {...form.getInputProps('excise_duty')} onChange={(e) => changeExciseDuty(e)}/>
            </label>
          </form>
          <form>
            <label>Extra Charges
              <NumberInput ml={10} mt={9} style={{width: 180, display: 'inline-block'}} hideControls placeholder="0" {...form.getInputProps('extra_charges')} onChange={(e) => changeExtraDuty(e)}/>
            </label>
          </form>
           <form>
            <label>Discount
              <NumberInput ml={10} mt={9} style={{width: 180, display: 'inline-block'}} hideControls placeholder="0" {...form.getInputProps('total_discount')} onChange={(e) => changeTotalDiscount(e)}/>
            </label>
          </form>
          <form>
            <label>Total
              <NumberInput ml={10} mt={9} style={{width: 180, display: 'inline-block'}} disabled placeholder="0" {...form.getInputProps('total')}/>
            </label>
          </form>
          <form>
            <label>Cash Received
              <NumberInput ml={10} mt={9} style={{width: 180, display: 'inline-block'}} hideControls placeholder="0" {...form.getInputProps('cash_received')} onChange={(e) => changeCashReceived(e)}/>
            </label>
          </form>
          <form>
            <label>Balance
              <NumberInput ml={10} mt={9} style={{width: 180, display: 'inline-block'}} disabled placeholder="0" {...form.getInputProps('balance')}/>
            </label>
          </form>
        </div>
      </Grid.Col>
    </Grid>
  </Container>
  </Card>
  );
}