import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, NumberInput, Pagination, Modal, TextInput, Select, Notification } from '@mantine/core';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useForm } from '@mantine/form';
import Moment from 'react-moment';

export default function Accounts() {

  const [cookies] = useCookies(['user', 'token', 'abilities']);

  const[search, setSearch] = useState("");

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Accounts Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);

  // Account types with label and value
  const [typesData, setTypesData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // On Page Load
  useEffect(()=>{
    getAccounts(1);
    getAccountTypes();
  },[search]);

  // Account form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      name: '',
      code: '',
      account_type_id: '',
      account_number: '',
      balance: '',
      company_id: cookies.user.company_id,
      branch_id: cookies.user.branch_id,
      description: '',
      status: ''

    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Account Name field required' : null),
      code: (value) => (value.length < 2 ? 'Account Code field required' : null),
    },
  });

  // Functions

  // Reset Form
  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.uuid = '';
    form.values.name = '';
    form.values.code = '';
    form.values.account_type_id = '';
    form.values.company_id = cookies.user.company_id;
    form.values.description = '';
    form.values.status = '1';
  }

  // Get Accounts
  function getAccounts(page: any) {
    setFetching(true);
    http.get(`account/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }
    // Get Accounts types
    function getAccountTypes() {
    http.get('account-types', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setTypesData((curData) => [...curData, { value: item.id, label: item.name }]));
    });
  }

  // Create Account
  const createAccount = () => {
    resetForm();
    form.values.company_id = cookies.user.company_id;
    form.values.branch_id = cookies.user.branch_id;
    setTitle('Create New Account!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Account
  function addAccount() {
    setButtonLoading(true);
    http.post('account', form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getAccounts(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Account created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotification(error.response.data.message);
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    }); 
  }

  // Edit Account Function
  const editAccount = ({id, uuid, name, code, balance, account_type_id, type, company_id, status, description, contacts}: any) => {
    setTitle('Edit Account!');
    setButton('Update');
    resetForm();
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('name', name);
    form.setFieldValue('code', code);
    form.setFieldValue('type', type);
    form.setFieldValue('account_type_id', account_type_id);
    form.setFieldValue('balance', balance);
    form.setFieldValue('company_id', company_id);
    form.setFieldValue('description', description);
    form.setFieldValue('status', (status ? '1': '0'));
    setShowNotification(false);
    setOpenedDialog(true);
  }
  
  // Update Account
  function updateAccount() {
    setButtonLoading(true);
    http.put(`account/${form.values.id}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDialog(false);
      getAccounts(1);
      setNotificationType(true);
      setNotification('Account updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotificationType(false);
      setNotification(error.response.data.message);
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    }); 
  }

  // Select Account to delete
  const deleteAccount = ({uuid}: any) => {
    form.values.uuid = uuid;
    setOpenedDeleteDialog(true);
  }

  // Delete Account
  function deleteSelectedAccount() {
    setButtonLoading(true);
    http.delete('account/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      getAccounts(1);
      setNotificationType(true);
      setNotification('Account deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }); 
  }

  // function getTypeById({typeId}: any) {
  //   let type = accountTypes.find( ({id}: any) => id === typeId );
  //   if(type) {
  //     console.log(type);
  //     // return account_type.label;
  //   }
  // }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Accounts' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Accounts</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createAccount()} color="green" className="mt-15" mr={10}>Create New Account</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Account..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>  

      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        // provide data
        records={data}
        minHeight={90}
        fetching={fetching}
        // define columns
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          { accessor: 'code' },
          // { 
          //   accessor: 'account type',
          //    render: ({ account_type_id  }) => (
          //     <>{getTypeById(account_type_id)}</>
          //   )
          // },
          {
            accessor: 'status',
            render: ({ status  }) => (
              <>{status == '1' ? 'Enable' : 'Disable'}</>
            )
          },
          {
            accessor: 'last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },

          {
            accessor: 'actions',
            // this column has custom cell data rendering
            render: ({ id, uuid, name, balance, code, account_type_id, type, company_id, status, description, contacts }: any) => (
              <>
                <Button onClick={() => editAccount({id, uuid, name, account_type_id, balance, code, type, company_id, description, status, contacts})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteAccount({uuid})}>
                  Delete
                </Button>
              </>
            ),
            textAlignment: 'center',
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addAccount() : updateAccount())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Enter Account Name" withAsterisk {...form.getInputProps('name')} />
          <TextInput mt="sm" label="Code" placeholder="Enter Account Code" withAsterisk {...form.getInputProps('code')} />
          <TextInput mt="sm" label="Balance" placeholder="Enter Balance" withAsterisk {...form.getInputProps('balance')} />
          <Select
            mt="sm"
            label="Type"
            placeholder="Select Type"
            data={typesData}
            withAsterisk
            {...form.getInputProps('account_type_id')}
          />
          <TextInput mt="sm" label="Description" placeholder="Description" {...form.getInputProps('description')} />
          <Select
            mt="sm"
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Enable' },
              { value: '0', label: 'Disable' },
            ]}
            withAsterisk
            {...form.getInputProps('status')}
          />
          <div className="text-right">
            <Button color="red" type="submit" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedAccount()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getAccounts(e)} className="justify-content-center" mt={15} />
    </>
  );
}