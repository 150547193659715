import { TextInput, Select, Button, JsonInput } from '@mantine/core';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";



export default function ReceivePayment() {



  //Receive Payment Data
  const [company, setCompany] = useState("")
  const [amount, setAmount] = useState("")
  const [description, setDescription] = useState("")

  // const [cookies] = useCookies(['user', 'abilities']);
  // const abilities = cookies.user.abilities;
  // const ability_exist = abilities.find( ( name : any) => name === 'Receive Payments' );

  // if (!ability_exist) {
  //   return (
  //     <>
  //       <ErrorPage />
  //     </>
  //   );
  // }

  return (
    <>
    <h2 style={{width: 250, display: 'inline-block'}}>Receive Payment</h2>
     <Select
        mt={12}
        label="Customer"
        placeholder="Select One"
        data={[
          { value: 'ali', label: 'Ali' },
          { value: 'umar', label: 'Umar' },
        ]}
        value={company} onChange={(e) => e != null ? setCompany(e) : ''}
      />
      <TextInput label="Amount" placeholder="Enter Amount" mt={15} value={amount} onChange={(e) => setAmount(e.target.value)}/>
      <JsonInput mt={15}
      label="Description"
      placeholder="Enter Description"
      validationError="Invalid Write"
      formatOnBlur
      autosize
      minRows={4} value={description} onChange={(e) => e != null ? setDescription(e) : ''}
    />
      <Button mt={10} ml={890}>Save</Button>
    </>
  );
}
