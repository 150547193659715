import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Notification, Select } from '@mantine/core';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useForm } from '@mantine/form';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import Moment from 'react-moment';

export default function Categories() {

  const [cookies, setCookie] = useCookies(['token', 'user', 'abilities']);

  const[search, setSearch] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Categories Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);

  // On Page Load
  useEffect(()=>{
    getCategories(1);
  },[search]);

  // Category form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      name: '',
      code: '',
      company_id: cookies.user.company_id,
      status: '1',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Category field required' : null)
    }
  });

  // Functions

  // Reset Form
  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.uuid = '';
    form.values.name = '';
    form.values.code = '';
    form.values.company_id = cookies.user.company_id;
    form.values.status = '1';
  }

  // Get Categories
  function getCategories(page: any) {
    setFetching(true);
    http.get(`category/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Create Category Function
  const createCategory = () => {
    resetForm();
    setTitle('Create New Category!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Category
  function addCategory() {
    setButtonLoading(true);
    http.post(`category`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getCategories(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Category created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          form.setFieldError('name', error.response.data.errors.name.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Edit Category Function
  const editCategory = ({id, uuid, name, code, status }: any) => {
    setTitle('Edit Category!');
    setButton('Update');
    resetForm();
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('name', name);
    form.setFieldValue('code', code);
    form.setFieldValue('status', (status ? '1': '0'));
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Update Category
  function updateCategory() {
    setButtonLoading(true);
    http.put(`category/${form.values.uuid}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDialog(false);
      getCategories(1);
      setNotificationType(true);
      setNotification('Category updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          form.setFieldError('name', error.response.data.errors.name.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Select Category to delete
  const deleteCategory = ({uuid}: any) => {
    form.values.uuid = uuid;
    setOpenedDeleteDialog(true);
  }

  // Delete Category
  function deleteSelectedCategory() {
    setButtonLoading(true);
    http.delete('category/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      getCategories(1);
      setNotificationType(true);
      setNotification('Category deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }


  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Categories' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Categories</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createCategory()} color="green" className="mt-15" mr={10}>Create New Category</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Category..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div> 
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        records={data}
        minHeight={90}
        fetching={fetching}
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          { accessor: 'code' },
          {
            accessor: 'number of products',
            render: ({ items }: any) => (
              <>{items.length}</>
            )
          },
          {
            accessor: 'status',
            render: ({ status  }) => (
              <>{status === 1 ? 'Enable' : 'Disable'}</>
            )
          },
          {
            accessor: 'Last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            textAlignment: 'center',
            // this column has custom cell data rendering
            render: ({ id, uuid, name, code, status }) => (
              <>
                <Button onClick={() => editCategory({id, uuid, name, code, status})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteCategory({uuid})}>
                  Delete
                </Button>
              </>
            ),
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addCategory() : updateCategory())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Category Name" withAsterisk {...form.getInputProps('name')} />
          <TextInput mt="sm" label="Code" placeholder="Enter Code" {...form.getInputProps('code')} />
          <Select
            mt={12}
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Enable' },
              { value: '0', label: 'Disable' },
            ]}
            {...form.getInputProps('status')}
            withAsterisk
          />
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => deleteSelectedCategory()} loading={buttonLoading}>
              Yes
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getCategories(e)} className="justify-content-center" mt={15} />
    </>
  );
}