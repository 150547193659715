import React, { useEffect, useState } from 'react';
import { Table, TextInput, Button, Group, Title, NumberInput, Container, ActionIcon } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { IconEdit, IconTrash } from '@tabler/icons';
import { toast } from 'react-toastify'; // Import React-Toastify

// Ensure you import the required CSS for React-Toastify
import 'react-toastify/dist/ReactToastify.css';

interface Customer {
  id: number;
  name: string;
  balance: number;
  inception_date: string | null;
  company_id: number;
}

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [name, setName] = useState('');
  const [balance, setBalance] = useState<number | undefined>();
  const [inceptionDate, setInceptionDate] = useState<Date | null>(null);
  const [editingCustomer, setEditingCustomer] = useState<Customer | null>(null);
  const [cookies] = useCookies(['token', 'user']);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(`${apiUrl}/customer`, {
        headers: { Authorization: 'Bearer ' + cookies.token },
      })
      .then((response) => {
        if (response.data.status === 'success' && Array.isArray(response.data.data)) {
          setCustomers(response.data.data);
        } else {
          setCustomers([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching customers:', error);
      });
  }, [apiUrl, cookies.token]);

  const addCustomer = () => {
    if (name && inceptionDate && cookies.user?.company_id) {
      const newCustomer = {
        name,
        balance: balance || 0,
        inception_date: inceptionDate.toISOString().split('T')[0],
        company_id: cookies.user.company_id,
        status: true,
      };

      axios
        .post(`${apiUrl}/customer`, newCustomer, {
          headers: { Authorization: 'Bearer ' + cookies.token },
        })
        .then((response) => {
          if (response.data && response.data.status === 'success') {
            setCustomers((prev) => [...prev, response.data.data]);
            toast.success(`${name} has been added successfully.`);
          } else {
            toast.error('Failed to add customer.');
          }
          setName('');
          setBalance(undefined);
          setInceptionDate(null);
        })
        .catch((error) => {
          console.error('Error adding customer:', error);
          toast.error('An error occurred while adding the customer.');
        });
    } else {
      toast.warning('Please fill in all required fields.');
    }
  };

  const updateCustomer = () => {
    if (editingCustomer && name && inceptionDate && cookies.user?.company_id) {
      const updatedCustomer = {
        ...editingCustomer,
        name,
        balance: balance || 0,
        inception_date: inceptionDate.toISOString().split('T')[0],
        company_id: cookies.user.company_id,
        status: true,
      };

      axios
        .put(`${apiUrl}/customer/${editingCustomer.id}`, updatedCustomer, {
          headers: { Authorization: 'Bearer ' + cookies.token },
        })
        .then((response) => {
          if (response.data && response.data.status === 'success') {
            setCustomers((prev) =>
              prev.map((customer) => (customer.id === editingCustomer.id ? response.data.data : customer))
            );
            setEditingCustomer(null);
            setName('');
            setBalance(undefined);
            setInceptionDate(null);
            toast.info(`${name} has been updated successfully.`);
          } else {
            toast.error('Failed to update customer.');
          }
        })
        .catch((error) => {
          console.error('Error updating customer:', error);
          toast.error('An error occurred while updating the customer.');
        });
    }
  };

  const deleteCustomer = (id: number) => {
    // Show a custom toast with actions
    toast.warning(
      <div>
        <p>Are you sure you want to delete this customer?</p>
        <div>
          <Button
            onClick={() => {
              axios
                .delete(`${apiUrl}/customer/${id}`, {
                  headers: { Authorization: 'Bearer ' + cookies.token },
                })
                .then(() => {
                  setCustomers((prev) => prev.filter((customer) => customer.id !== id));
                  toast.success('Customer has been deleted successfully.');
                })
                .catch((error) => {
                  console.error('Error deleting customer:', error);
                  toast.error('An error occurred while deleting the customer.');
                });
            }}
            color="green"
            size="xs"
            style={{ marginRight: '10px' }}
          >
            Yes
          </Button>
          <Button
            onClick={() => toast.dismiss()} // Dismiss the toast
            color="gray"
            size="xs"
          >
            No
          </Button>
        </div>
      </div>,
      {
        autoClose: false,
        closeButton: false,
        position: 'top-center',
      }
    );
  };

  const handleEdit = (customer: Customer) => {
    setEditingCustomer(customer);
    setName(customer.name);
    setBalance(customer.balance);
    setInceptionDate(customer.inception_date ? new Date(customer.inception_date) : null);
  };

  return (
    <Container>
      <Title>Customers</Title>
      <Group>
        <TextInput
          placeholder="Customer Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <NumberInput
          placeholder="Balance"
          value={balance}
          onChange={setBalance}
        />
        <DatePicker
          placeholder="Inception Date"
          value={inceptionDate}
          onChange={setInceptionDate}
        />
        <Button onClick={editingCustomer ? updateCustomer : addCustomer}>
          {editingCustomer ? 'Update Customer' : 'Add Customer'}
        </Button>
      </Group>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Balance</th>
            <th>Inception Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(customers) &&
            customers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.name}</td>
                <td>{customer.balance}</td>
                <td>{customer.inception_date ? new Date(customer.inception_date).toLocaleDateString() : 'N/A'}</td>
                <td>
                  <Group>
                    <ActionIcon onClick={() => handleEdit(customer)} color="blue">
                      <IconEdit />
                    </ActionIcon>
                    <ActionIcon onClick={() => deleteCustomer(customer.id)} color="red">
                      <IconTrash />
                    </ActionIcon>
                  </Group>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Customers;
