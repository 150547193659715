import { useState } from 'react';
import TopHeader from './layout/Header/index';
import SideBar from './layout/SideBar/index';
import { useCookies } from 'react-cookie';
import Login from './pages/Login';
import { Outlet } from "react-router-dom";
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Container
} from '@mantine/core';

export default function AppShellDemo() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const [cookies] = useCookies(['token'])
  
  if (!cookies.token) {
    return (
      <>
        <Login />
      </>
    );
  }

  return (
    <AppShell
      styles={{
        main: {
          background: '#fff',
        },
      }}
      navbarOffsetBreakpoint="sm"
      navbar={
        <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 250, lg: 300 }}>
          <SideBar />
        </Navbar>
      }
      header={
        <Header height={{ base: 50, md: 70 }} p="md">
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <TopHeader />
          </div>
        </Header>
      }
    >
      <Container>
        <Outlet />
      </Container>
    </AppShell>
  );
}