import React, { useState } from 'react';
import { useEffect } from "react";
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Checkbox, PasswordInput, Select, Notification } from '@mantine/core';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import http from '../axios/index';
import { useForm } from '@mantine/form';
import { useCookies } from 'react-cookie';
import Moment from 'react-moment';
import ErrorPage from "./errors/401";

export default function Users() {

  const [cookies, setCookie] = useCookies(['token', 'user', 'abilities']);
  
  const[search, setSearch] = useState("");

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);
  const [openedAbilitiesDialog, setOpenedAbilitiesDialog] = useState(false);

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  //  User Dialog Title and Button
  const [title, setTitle] = useState("")
  const [button, setButton] = useState("")

  // User Data
  const [data, setData] = useState([]);

  // User Abilities with label and value
  const [abilitiesData, setAbilitiesData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Companies with label and value
  const [companiesData, setCompaniesData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Branches with label and value
  const [branchesData, setBranchesData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);
  
  // Roles
  const [roles, setRoles] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Check Load Data
  const [loadData, setLoadData] = useState(false);

  // On Page Load
  useEffect(()=>{
    getUsers(1);
    if(!loadData) {
      getCompanies();
      getBranches(cookies.user.company_id);
      getAbilities();
      setLoadData(true);
      if(cookies.user.role === 'Super Admin') {
        setRoles([]);
        setRoles((curData) => [...curData, { value: 'User', label: 'User' }]);
        setRoles((curData) => [...curData, { value: 'Admin', label: 'Admin' }]);
        setRoles((curData) => [...curData, { value: 'Super Admin', label: 'Super Admin' }]);
      } else {
        setRoles([]);
        setRoles((curData) => [...curData, { value: 'User', label: 'User' }]);
      }
    }
  },[search, loadData]);

  // User form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      phone: '',
      address: '',
      company_id: cookies.user.company_id,
      branch_id: '',
      role: 'User',
      status: '1',
    },

    // Form Validations
    validate: {
      firstname: (value) => (value.length < 2 ? 'First name must have at least 2 letters' : null),
      lastname: (value) => (value.length < 2 ? 'Last name must have at least 2 letters' : null),
      username: (value) => (value.length < 2 ? 'User name must have at least 2 letters' : null),
      email: (value) => (value.length < 3 ? 'Email field Required' : (/^\S+@\S+$/.test(value) ? null : 'Invalid email')),
      phone: (value) => (value.length < 11 ? 'Phone Number must have at least 11 digits' : null),
      password: (value) => (button === 'Save' ? (value.length < 3 ? 'Password must have at least 3 letters' : null) : null),
      confirm_password: (value, values) =>
        value !== values.password ? 'Passwords did not match' : null,
    },
  });

  // Abilities form
  const abilitiesForm = useForm({
    initialValues: {
      abilities: [],
      user: ''
    },
  });

  // Functions

  // Reset Form
  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.uuid = '';
    form.values.firstname = '';
    form.values.lastname = '';
    form.values.username = '';
    form.values.email = '';
    form.values.password = '';
    form.values.confirm_password = '';
    form.values.phone = '';
    form.values.address = '';
    form.values.company_id = cookies.user.company_id;
    form.values.branch_id = '';
    form.values.role = 'User';
    form.values.status = '1';
  }

  // Get Users
  function getUsers(page: any) {
    setFetching(true);
    http.get(`users/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Get Companies
  function getCompanies() {
    http.get('all-active-companies', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setCompaniesData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  // Get Branches
  function getBranches(company_id: any) {
    form.setFieldValue('company_id', company_id);
    http.get(`company-branches/${company_id}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setBranchesData([]);
      response.data.data.map((item: any) => setBranchesData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  // Get Abilities
  function getAbilities() {
    http.get('abilities', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setAbilitiesData((curData) => [...curData, { value: item.id.toString(), label: item.name }]))
    });
  }

  // Create User
  const createUser = () => {
    setTitle('Create New User!');
    setButton('Save');
    resetForm();
    setOpenedDialog(true);
  }

  // Add User
  function addUser() {
    setButtonLoading(true);
    http.post('users', form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getUsers(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotification('User created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.branch_id) {
          form.setFieldError('branch_id', 'Branch field required');
        }
        if (error.response.data.errors.phone) {
          form.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          form.setFieldError('email', error.response.data.errors.email.shift());
        }
        if (error.response.data.errors.username) {
          form.setFieldError('username', error.response.data.errors.username.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Edit User
  const editUser = ({ id, uuid, firstname, lastname, username, email, phone, address, company_id, branch_id, is_activate, role}: any) => {
    setTitle('Edit User!');
    setButton('Update');
    resetForm();
    debugger
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('firstname', firstname);
    form.setFieldValue('lastname', lastname);
    form.setFieldValue('username', username);
    form.setFieldValue('email', email);
    form.setFieldValue('phone', phone ? phone : '');
    form.setFieldValue('address', address ? phone : '');
    form.setFieldValue('company_id', company_id);
    form.setFieldValue('branch_id', branch_id);
    form.setFieldValue('role', role);
    form.setFieldValue('is_activate', is_activate.toString());
    setOpenedDialog(true);
  }

  // Update User
  function updateUser() {
    setButtonLoading(true);
    http.put(`users/${form.values.id}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getUsers(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotification('User updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.branch_id) {
          form.setFieldError('branch_id', 'Branch field required');
        }
        if (error.response.data.errors.phone) {
          form.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          form.setFieldError('email', error.response.data.errors.email.shift());
        }
        if (error.response.data.errors.username) {
          form.setFieldError('username', error.response.data.errors.username.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }  

  // Edit User Abilities
  const editAbilities = ({id, abilities}: any) => {
    let user_abilities: any = [];
    abilities.map((item: any) => user_abilities.push(item.id.toString()));
    abilitiesForm.values.abilities = user_abilities;
    abilitiesForm.values.user = id;
    setOpenedAbilitiesDialog(true);
  }

  // Assign Abilites
  function assignAbilities() {
    setButtonLoading(true);
    http.post('assign-abilities', {user: abilitiesForm.values.user, abilities: abilitiesForm.values.abilities}, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      if(response.data.data.user.id === cookies.user.id) {
        setCookie('user', JSON.stringify(response.data.data.user));
        setCookie('abilities', JSON.stringify(response.data.data.user_abilities));
      }
      getUsers(1);
      setButtonLoading(false);
      setOpenedAbilitiesDialog(false);
      setNotification('Abilities assigned to user successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .then((error) => {
      setButtonLoading(false);
      setOpenedAbilitiesDialog(false);
    })
  }

  // Select User to delete
  const deleteUser = ({uuid}: any) => {
    form.values.uuid = uuid;
    setOpenedDeleteDialog(true);
  }

  // Delete User
  function deleteSelectedUser() {
    setButtonLoading(true);
    http.delete('users/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      form.reset();
      getUsers(1);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      setNotification('User deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .then((error) => {
      form.reset();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }

  function selectAllAbilities(e: Boolean) {
    if (e) {
      let abilities: any = [];
      abilitiesForm.setFieldValue('abilities', []);
      for(let i = 0; i < abilitiesData.length; i++) {
        abilities.push(abilitiesData[i].value);
      }
      abilitiesForm.setFieldValue('abilities', abilities);
    } else {
      abilitiesForm.setFieldValue('abilities', []);
    }
  }

  // Abilities Checkboxes
  const abilitiesCheckbox = abilitiesData.map((item, index) => <Checkbox key={index} value={item.value} label={item.label} />);

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Users' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  function renderCompaniesSelect() {
    if(cookies.user.role === 'Super Admin') {
      return (
        <Select
          mt={12}
          label="Company"
          placeholder="Select Company"
          data={companiesData}
          {...form.getInputProps('company_id')}
          onChange={(e) => getBranches(e)}
          withAsterisk
        />
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Users</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createUser()} color="green" className="mt-15" mr={10}>Create New User</Button>
          <TextInput icon={<IconSearch />} placeholder="Search User..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div>
      
      {/*Users DataTable*/}          
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        // provide data
        records={data}
        minHeight={90}
        fetching={fetching}
        // define columns
        columns={[
          {
            accessor: 'id',
            // this column has a custom title
            title: 'ID',
            // right-align column
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'username' },
          { accessor: 'email' },
          { accessor: 'role' },
          {
            accessor: 'status',
            render: ({ is_activate  }) => (
              <>{is_activate === 1 ? 'Enable' : 'Disable'}</>
            )
          },
          {
            accessor: 'last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'abilities',
            // this column has custom cell data rendering
            render: ({ id, abilities }) => (
              <>
                <Button ml={10} color="orange" onClick={() => editAbilities({id, abilities})}>
                  Abilities
                </Button>
              </>
            ),
            width: 120,
            textAlignment: 'center'
          },
          {
            accessor: 'actions',
            // this column has custom cell data rendering
            render: ({ id, uuid, firstname, lastname, username, email, phone, address, company_id, branch_id, is_activate, role }) => (
              <>
                <Button onClick={() => editUser({ id, uuid, firstname, lastname, username, email, phone, address, company_id, branch_id, is_activate, role})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteUser({uuid})}>
                  Delete
                </Button>
              </>
            ),
            width: 200,
            textAlignment: 'center'
          },
        ]}
      />

      {/*User Modal*/}
      <Modal
        overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addUser() : updateUser())}>
          {renderNotification()}
          <TextInput mt="sm" label="First Name" placeholder="Enter First Name" withAsterisk {...form.getInputProps('firstname')} />
          <TextInput mt="sm" label="Last Name" placeholder="Enter Last Name" withAsterisk {...form.getInputProps('lastname')} />
          <TextInput mt="sm" label="User Name" placeholder="Enter User Name" withAsterisk {...form.getInputProps('username')} />
          <TextInput mt="sm" label="Email" placeholder="Enter Email" withAsterisk {...form.getInputProps('email')} />
          <PasswordInput
            mt={12}
            placeholder="Password"
            label="Password"
            description="Password must include at least one letter"
            withAsterisk {...form.getInputProps('password')}
          />
          <PasswordInput
            mt={12}
            placeholder=" Confirm Password"
            label="Confirm Password"
            description="Password must include at least one letter, number and special character"
            withAsterisk {...form.getInputProps('confirm_password')}
          />
          <TextInput mt="sm" label="Mobile Number" placeholder="Enter Mobile Number" type="number" withAsterisk {...form.getInputProps('phone')} />
          <TextInput mt="sm" label="Address" placeholder="Enter Address" {...form.getInputProps('address')} />
          {renderCompaniesSelect()}
          <Select
            mt={12}
            label="Branch"
            placeholder="Select Branch"
            data={branchesData}
            {...form.getInputProps('branch_id')}
            nothingFound="No branch found"
          />
          <Select
            mt={12}
            label="Roles"
            placeholder="Select Roles"
            data={roles}
            {...form.getInputProps('role')}
            withAsterisk
          />
          <Select
            mt={12}
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Enable' },
              { value: '0', label: 'Disable' },
            ]}
            {...form.getInputProps('status')}
            withAsterisk
          />
          <div className="text-right">
            <Button color="red" type="submit" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>

      {/*Confirm Delete Modal*/}
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedUser()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>

      {/*Abilities Modal*/}
      <Modal
        opened={openedAbilitiesDialog}
        onClose={() => setOpenedAbilitiesDialog(false)}
        title="Abilities!"
      >
        <form onSubmit={abilitiesForm.onSubmit((values) => assignAbilities())}>
          {/*Abilities Checkboxes*/}
          <Checkbox label="Select All" mb={4}  onChange={(event) => selectAllAbilities(event.currentTarget.checked)} />
          <Checkbox.Group {...abilitiesForm.getInputProps('abilities')} orientation="vertical" spacing="xs" withAsterisk>
            {abilitiesCheckbox}
          </Checkbox.Group>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedAbilitiesDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              Assign
            </Button>
          </div>
        </form>
      </Modal>
      
      {/*Pagination*/}
      <Pagination total={total} page={current_page} onChange={(e) => getUsers(e)} className="justify-content-center" mt={15} />
    </>
  );
}