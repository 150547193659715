import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, Modal, TextInput, Notification, Select, NumberInput } from '@mantine/core';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import { useForm } from '@mantine/form';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import Moment from 'react-moment';

export default function Items() {

  const [cookies] = useCookies(['token', 'user', 'abilities']);

  const[search, setSearch] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("");
  const [button, setButton] = useState("");

  // Items Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);

  // Categories with label and value
  const [categoriesData, setCategoriesData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);


   // Units with label and value
  const [unitsData, setUnitsData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Items form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      name: '',
      code: '',
      price: 0,
      price_one: 0,
      price_two: 0,
      barcode: '',
      sales_tax: 0,
      company_id: cookies.user.company_id,
      unit_id: '',
      category_id: '',
      status: '1',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Item field required' : null),
      category_id: (value) => (value.length < 2 ? 'Category field required' : null),
    },
  });

  // Check Load Data
  const [loadData, setLoadData] = useState(false);


  // On Page Load
  useEffect(()=>{
    getItems(1);
    if(!loadData) {
      getUnits();
      getCategories();
      setLoadData(true);
    }
  },[search, loadData]);

  // Functions

  // Reset Form
  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.uuid = '';
    form.values.name = '';
    form.values.code = '';
    form.values.barcode = '';
    form.values.price = 0;
    form.values.price_one = 0;
    form.values.price_two = 0;
    form.values.sales_tax = 0;
    form.values.company_id = cookies.user.company_id;
    form.values.unit_id = '';
    form.values.category_id = '';
    form.values.status = '1';
  }

  // Get Items
  function getItems(page: any) {
    setFetching(true);
    http.get(`item/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Get Units
  function getUnits() {
    http.get('unit', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setUnitsData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  // Get Categories
  function getCategories() {
    http.get('category', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setCategoriesData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  // Create Item Function
  const createItem = () => {
    resetForm();
    setTitle('Create New Item!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Item
  function addItem() {
    setButtonLoading(true);
    http.post(`item`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getItems(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Item created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          form.setFieldError('name', error.response.data.errors.name.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Edit Item Function
  const editItem = ({id, uuid, name, code, price, price_one, price_two, sales_tax, barcode, unit_id, category_id, status}: any) => {
    setTitle('Edit Item!');
    setButton('Update');
    resetForm();
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('name', name);
    form.setFieldValue('code', code ? code : '');
    form.setFieldValue('price', price);
    form.setFieldValue('price_one', price_one);
    form.setFieldValue('price_two', price_two);
    form.setFieldValue('barcode', barcode ? barcode : '');
    form.setFieldValue('sales_tax', sales_tax);
    form.setFieldValue('unit_id', unit_id);
    form.setFieldValue('category_id', category_id);
    form.setFieldValue('status', (status ? '1': '0'));
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Update Item
  function updateItem() {
    setButtonLoading(true);
    http.put(`item/${form.values.id}`, form.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      form.reset();
      setButtonLoading(false);
      setOpenedDialog(false);
      getItems(1);
      setNotificationType(true);
      setNotification('Item updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setNotificationType(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.name) {
          form.setFieldError('name', error.response.data.errors.name.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Select Item to delete
  const deleteItem = ({uuid}: any) => {
    form.values.uuid = uuid;
    setOpenedDeleteDialog(true);
  }

  // Delete Item
  function deleteSelectedItem() {
    setButtonLoading(true);
    http.delete('item/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      getItems(1);
      setNotificationType(true);
      setNotification('Item deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Items' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Items</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createItem()} color="green" className="mt-15" mr={10}>Create New Item</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Item..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div> 
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        records={data}
        minHeight={100}
        fetching={fetching}
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          { 
            accessor: 'category',
            render: ({category}: any) => (
              <>{category ? category.name : 'NONE'}</>
            )
          },
          { accessor: 'stock' },
          {
            accessor: 'status',
            render: ({ status  }) => (
              <>{status === 1 ? 'Enable' : 'Disable'}</>
            )
          },
          {
            accessor: 'Last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            textAlignment: 'center',
            // this column has custom cell data rendering
            render: ({ id, uuid, name, code, price, price_one, price_two, sales_tax, barcode, unit_id, category_id, status }) => (
              <>
                <Button onClick={() => editItem({id, uuid, name, code, price, price_one, price_two, sales_tax, barcode, unit_id, category_id, status })}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteItem({uuid})}>
                  Delete
                </Button>
              </>
            ),
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addItem() : updateItem())}>
          {renderNotification()}
          <TextInput mt="sm" label="Item Name" placeholder="Enter Item" withAsterisk {...form.getInputProps('name')} />
          <TextInput mt="sm" label="Code" placeholder="Enter Code" withAsterisk {...form.getInputProps('code')} />
          <Select
            mt={12}
            label="Category"
            placeholder="Select Category"
            data={categoriesData}
            nothingFound="Nothing found"
            searchable
            clearable
            {...form.getInputProps('category_id')}
          />
          <Select
            mt={12}
            label="Units"
            placeholder="Select Unit"
            data={unitsData}
            {...form.getInputProps('unit_id')}
          />
          <NumberInput
            mt="sm" 
            label="Price" 
            placeholder="Enter Sale Price"
            min={0}
            withAsterisk
            hideControls
            {...form.getInputProps('price')}
          />
          <NumberInput
            mt="sm" 
            label="Price One" 
            placeholder="Enter Price One"
            min={0}
            withAsterisk
            hideControls
            {...form.getInputProps('price_one')}
          />
          <NumberInput
            mt="sm" 
            label="Price Two" 
            placeholder="Enter Price Two"
            min={0}
            withAsterisk
            hideControls
            {...form.getInputProps('price_two')}
          />
          <NumberInput
            mt="sm" label="Sales Tax" placeholder="Enter Sales Tax"
            defaultValue={0.05}
            precision={2}
            min={0}
            step={0.05}
            max={100}
            withAsterisk
            {...form.getInputProps('sales_tax')}
          />
          <TextInput mt="sm" label="Barcode" placeholder="Barcode" withAsterisk {...form.getInputProps('barcode')} />
          <Select
            mt={12}
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Enable' },
              { value: '0', label: 'Disable' },
            ]}
            {...form.getInputProps('status')}
          />
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)} >
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedItem()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getItems(e)} className="justify-content-center" mt={15} />
    </>
  );
}