import React, { useEffect } from 'react';
import { Container, Radio, Select, Modal, TextInput, createStyles, Card, Group, Box, Text, Button, NumberInput, SimpleGrid, Grid, Image, Notification, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { randomId } from '@mantine/hooks';
import { IconCheck, IconX, IconUser } from '@tabler/icons';
import { useForm } from '@mantine/form';
import { useCookies } from 'react-cookie';
import ErrorPage from "./errors/401";
import http from '../axios/index';
import useState from 'react-usestateref';
import moment from "moment";


export default function CreateSalesInvoice() {


  const [cookies] = useCookies(['user', 'token', 'abilities']);
  const user = cookies.user;

  const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  }));
  const { classes } = useStyles();

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  // Button Loading
  const [buttonLoading, setButtonLoading] = useState(false);
  
  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);
  
  // Items with label and value
  const [itemsData, setItemsData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Units with label and value
  const [unitData, setUnitData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Customers with label and value
  const [customersData, setCustomersData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);
  const [customerStatus, setCustomerStatus, customerStatusRef] = useState<number | null>(0);

  // Invoice Data
  const [invoiceId, setInvoiceId] = useState("");
  const [date, setDate] = useState<Date | null>(new Date);
  const [term, setTerm] = useState('1');
  const [customerId, setCustomerId, customerIdRef] = useState<string | null>(null);
  const [subTotal, setSubTotal, subTotalRef] = useState<number>(0);
  const [gst, setGst, gstRef] = useState<number>(0);
  const [further, setFurther, furtherRef] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [invoiceNote, setInvoiceNote] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");

  // Items 
  const [items, setItems] = useState<
    Array<{
        product: any,
        quantity: number,
        unit_price: number,
        unit_id: any,
        total: number,
        key: string
    }>
  >([]);

  // Customer form
  const customerForm = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      address: '',
      business_name: '',
      ntn_number: '',
      gst_number: '',
      cnic_number: '',
      strn_number: '',
      company_id: cookies.user.company_id,
      balance: 0,
      status: '',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 3 ? 'Customer Name Required' : null),
      phone: (value) => (value.length < 11 ? 'Phone Required' : null),
    },
  });


  // On Page Load{
  useEffect(()=>{
    getItems();
    getUnits();
    getCustomers();
    getInvoiceId();
    setItems((curData) => [...curData, { product: null, quantity: 1, unit_price: 0, unit_id: null, total: 0, key: randomId() }])
    setInvoiceNote('We hope our service and work brought a smile to your face and made your life just a little easier.');
  },[]);

  // Functions

  // Get Items
  function getItems() {
    http.get('item', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setItemsData((curData) => [...curData, { value: item, label: item.name }]));
    });
  }

  // Get Units
  function getUnits() {
    http.get('unit', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((unit: any) => setUnitData((curData) => [...curData, { value: unit.id, label: unit.name }]));
    });
  }

  // Get Customers
  function getCustomers() {
    http.get('customer', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setCustomersData((curData) => [...curData, { value: item, label: item.name }]));
    });
  }

  // Create Customer Function
  const createCustomer = () => {
    customerForm.reset();
    setOpenedDialog(true);
  }

  // Add Customer
  function addCustomer() {
    setButtonLoading(true);
    http.post('customer', customerForm.values, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      customerForm.reset();
      getCustomers();
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Customer created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.strn_number) {
          customerForm.setFieldError('strn_number', error.response.data.errors.strn_number.shift());
        }
        if (error.response.data.errors.ntn_number) {
          customerForm.setFieldError('ntn_number', error.response.data.errors.ntn_number.shift());
        }
        if (error.response.data.errors.gst_number) {
          customerForm.setFieldError('gst_number', error.response.data.errors.gst_number.shift());
        }
        if (error.response.data.errors.cnic_number) {
          customerForm.setFieldError('cnic_number', error.response.data.errors.cnic_number.shift());
        }
        if (error.response.data.errors.phone) {
          customerForm.setFieldError('phone', error.response.data.errors.phone.shift());
        }
        if (error.response.data.errors.email) {
          customerForm.setFieldError('email', error.response.data.errors.email.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Get Invoice Id
  function getInvoiceId() {
    http.get('invoice-id', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setInvoiceId(response.data.data);
    });
  }

  // Select Customer Function
  const selectCustomer = (e: any) => {
    setCustomerId(e.id);
    setCustomerStatus(e.status);
    calcTotal();
  }

  function removeRow(index: number) {
    if(items.length > 1) {
      items.splice(index, 1);
    }
  }

  function changeAmount(value: any, index: number) {
    items[index].unit_price = value;
    calcRowTotal(index);
  }

  function changeQty(value: any, index: number) {
    items[index].quantity = value;
    calcRowTotal(index);
  }

  function changeUnit(value: any, index: number) {
    items[index].unit_id = value;
    calcRowTotal(index);
  }
  
  function calcRowTotal(index: number) {
    let item = items[index];
    items[index].total = items[index].unit_price * items[index].quantity;
    calcSubTotal();
  }

  function calcSubTotal() {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      total = total + items[i].total;
    }
    setSubTotal(total);
    calcTotal();
  }

  function calcTotal() {
    // Calculate GST
    let gstTax = (subTotalRef.current * 18) / 100;
    setGst(gstTax);
    // Calculate Further
    let furtherTax = (subTotalRef.current * 3) / 100;
    setFurther(furtherTax);
    
    if (customerIdRef.current && customerStatusRef.current) {
      setFurther(0);
    }
    setTotal(subTotalRef.current + gstRef.current + furtherRef.current);
  }


  function saveInvoice() {
    const formData = new FormData();
    formData.append("invoiceId", invoiceId);
    formData.append("invoice_number", invoiceNumber);
    formData.append("date", moment(date).format("DD-MM-YYYY") as any);
    formData.append("term", term);
    formData.append("type", "sale");
    formData.append("customer", customerId as any);
    formData.append("note", invoiceNote);
    formData.append("sub_total", subTotal as any);
    formData.append("excise_duty", gst as any);
    formData.append("extra_charges", further as any);
    formData.append("total", total as any);
    formData.append("items", JSON.stringify(items) as any);
    http.post('save-invoice', formData, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.invoice_enteries.map((item: any) => setItems((curData) => [...curData, { product: item.item, quantity: item.quantity, unit_price: item.unit_price, total: item.total, unit_id: item.unit_id, key: randomId()
       }]));
      setSubTotal(response.data.invoice.sub_total);
      invoicePreview(response.data.invoice);
      window.location.reload();
    });
  }


   function invoicePreview(invoice: any) {
    window.open(`/invoice/${invoice.id}`, '_blank', 'noopener,noreferrer')
  }


  function changeItem(product: any, index: number) {
    let price = product.price ? product.price : 0;
  
    items[index].product = product;
    items[index].unit_price = price;
    items[index].quantity = 1;
  }

  const fields = items.map((item: any, index: any) => (
    <Group key={item.key} mt="xs">
      <Select
        placeholder="Select Item"
        searchable
        data={itemsData}
        sx={{ flex: 1 }}
        onChange={(e) => changeItem(e, index)} 
      />
      <NumberInput
        placeholder="Quantity"
        withAsterisk
        sx={{ flex: 1 }}
        min={1}
        hideControls
        value={item.quantity}
        onChange={(e) => changeQty(e, index)}
      />
      <NumberInput
        placeholder="Unit Price"
        withAsterisk
        sx={{ flex: 1 }}
        min={1}
        hideControls
        value={item.unit_price}
        onChange={(e) => changeAmount(e, index)}
      />
      <Select
        placeholder="Select Unit"
        searchable
        data={unitData}
        sx={{ flex: 1 }}
        onChange={(e) => changeUnit(e, index)}
      />
      <NumberInput
        placeholder="0"
        defaultValue={0}
        sx={{ flex: 1 }}
        value={item.total}
        disabled
      />
    </Group>
  ));

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Sales Invoice' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      <Card withBorder radius="md" className={classes.card} mt={15}>
        <Container my="md">
          <SimpleGrid cols={2}>
            <Card withBorder radius="md">
              <h2 className="mt-0">Sale Invoice</h2>
              <div style={{display: 'flex'}}>
                <div className="d-inline-block">
                  <Image
                    radius="md"
                    src={`${process.env.REACT_APP_WEB_URL}${user.branch.company.logo ? user.branch.company.logo : ''}`}
                    height={110}
                    width={120}
                  />
                </div>
                <div className="d-inline-block ml-20">
                  <h2 className="mb-0 mt-0">{user.branch.company.name}</h2>
                  <p className="mt-0 mb-0">{user.branch.address}</p>
                  <ul className="contacts mt-0">
                    {user.branch.contacts.map(function({value, id, type}: any){
                      return type === 'phone' ? <li key={ id }>{value}</li> : '';
                    })}
                  </ul>
                </div>
              </div>
            </Card>
            <div>
            <TextInput 
              mt="sm" 
              label="Invoice #" 
              placeholder="Enter Invoice" 
              withAsterisk 
              value={invoiceNumber}
              onChange={(event) => setInvoiceNumber(event.currentTarget.value)} />
              <DatePicker mt={10} placeholder="Pick date" label="Created" withAsterisk value={date} onChange={setDate} />
              <Radio.Group
                orientation="vertical"
                withAsterisk
                value={term}
                onChange={setTerm}
              >
                <Radio value="1" label="Cash" mb={-10}/>
                <Radio value="0" label="Credit" />
              </Radio.Group>
            </div>
          </SimpleGrid>
          <Select ml={5}
            style={{width: 300, display: 'inline-block'}}
            label="Creatable Select"
            data={customersData}
            placeholder="Select items"
            nothingFound="Loading..."
            icon={<IconUser size="1rem" />}
            onChange={(e) => selectCustomer(e)}
            searchable creatable clearable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              setCustomersData((current) => [...current, item]);
              return item;
            }}
          />
          <Button my={10} ml={10} onClick={() => createCustomer()}>Add New Customer</Button>
        <div className="w-100">
          <Card withBorder radius="md" mt={15} className="overflow">
            <Box mx="auto">
              {fields.length > 0 ? (
                <Group mb="xs">
                  <Text weight={500} size="sm" sx={{ flex: 1 }}>
                    Item
                  </Text>
                  <Text weight={500} size="sm" sx={{ flex: 1 }}>
                    Quantity
                  </Text>
                  <Text weight={500} size="sm" sx={{ flex: 1 }}>
                    Unit Price
                  </Text>
                  <Text weight={500} size="sm" sx={{ flex: 1 }}>
                    Unit
                  </Text>
                  <Text weight={800} size="sm" sx={{ flex: 1 }} mr={30}>
                    Total
                  </Text>
                </Group>
              ) : (
                <Text color="dimmed" align="left">
                  No Row Here...
                </Text>
              )}
              {fields}
              <Group position="left" mt="md">
                <Button
                  onClick={() =>
                    setItems((curData) => [...curData, { product: null, quantity: 1, unit_price: 0, unit_id: null, total: 0, key: randomId() }])
                  }
                >
                  Add Item
                </Button>
              </Group>
            </Box>
          </Card>
        </div>
      <Grid grow>
        <Grid.Col span={4}>
          <Textarea mt={15}
            placeholder="Enter Note"
            label="Customer Note"
            value={invoiceNote}
            onChange={(event) => setInvoiceNote(event.currentTarget.value)}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <div className="text-right">
            <form>
              <label>Sub Total
                <NumberInput
                  placeholder="0"
                  sx={{ flex: 1 }}
                  ml={10} mt={9} 
                  style={{width: 180, display: 'inline-block'}}
                  value={subTotal}
                  disabled
                />
              </label>
            </form>
            <form>
              <label>GST%
                <NumberInput
                  placeholder="0"
                  withAsterisk
                  sx={{ flex: 1 }}
                  ml={10} mt={9} 
                  style={{width: 180, display: 'inline-block'}}
                  value={gst}
                  disabled
                />
              </label>
            </form>
            <form>
              <label>Further Tax%
                <NumberInput ml={10} mt={9} style={{width: 180, display: 'inline-block'}} hideControls placeholder="0" value={further} disabled />
              </label>
            </form>
            <form>
              <label>Total
                <NumberInput
                  placeholder="0"
                  withAsterisk
                  sx={{ flex: 1 }}
                  ml={10} mt={9} 
                  style={{width: 180, display: 'inline-block'}}
                  value={total}
                  disabled
                />
              </label>
            </form>
              <Button mt={9} onClick={() => saveInvoice()}>Save And Print</Button>
          </div>
        </Grid.Col>
      </Grid>
    </Container>
    </Card>
      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title="Create New Customer!"
      >
        <form onSubmit={customerForm.onSubmit((values) => addCustomer())}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Enter Name" withAsterisk {...customerForm.getInputProps('name')} />
          <TextInput mt="sm" label="Business Name" placeholder="Enter Business Name" withAsterisk {...customerForm.getInputProps('business_name')} />
          <TextInput mt="sm" label="Email" placeholder="Enter Email" {...customerForm.getInputProps('email')} />
          <TextInput mt="sm" placeholder="Enter Phone" label="Phone" {...customerForm.getInputProps('phone')} />
          <TextInput mt="sm" label="Address" placeholder="Enter Address" {...customerForm.getInputProps('address')} />
          <TextInput mt="sm" placeholder="Enter CNIC Number" label="CNIC" {...customerForm.getInputProps('cnic_number')} />
          <TextInput mt="sm" placeholder="Enter NTN Number" label="NTN" {...customerForm.getInputProps('ntn_number')} />
          <TextInput mt="sm" placeholder="Enter STRN" label="STRN" {...customerForm.getInputProps('strn_number')} />
          <Select
            mt={12}
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Registered' },
              { value: '0', label: 'Un-Registered' },
            ]}
            {...customerForm.getInputProps('status')}
          />
          <div className="text-right">
            <Button color="red" type="button" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              Save
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}