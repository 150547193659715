import React from 'react';
import { Title, Container } from '@mantine/core';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Title>Farmhouse Dashboard</Title>
      {/* Add your dashboard components here */}
    </Container>
  );
};

export default Dashboard;
