import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, Pagination, TextInput, Modal } from '@mantine/core';
import { useCookies } from 'react-cookie';
import { randomId } from '@mantine/hooks';
import http from '../axios/index';
import { useForm } from '@mantine/form';
import { IconSearch } from '@tabler/icons';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import ErrorPage from "./errors/401";

export default function Sales() {

   const navigate = useNavigate();

   function handleClick() {
    navigate("/sales/create");
  }

  let { id } = useParams();
  console.log(id);

  const [cookies, setCookie] = useCookies(['token', 'user', 'abilities']);

  const[search, setSearch] = useState("");

  // Fetching
  const [fetching, setFetching] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);


  // Invoices Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [notification, setNotification] = useState("");



  // On Page Load
  useEffect(()=>{
    getInvoices(1);
  },[search]);

    const form = useForm({
    initialValues: {
      invoiceId: '',
      id: '',
      created_at: new Date(),
      term: '1',
      type: 'sale',
      customer: '',
      note: '',
      sub_total: 0,
      excise_duty: 0,
      extra_charges: 0,
      total: 0,
      products: [
        {
          item: null,
          unit_price: 0,
          quantity: 0,
          toggle: true,
          total: 0,
          key: randomId(),
        }
      ]
    },
  });


  // Get Invoices
  function getInvoices(page: any) {
    setFetching(true);
    http.get(`get-invoices/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  const deleteInvoice = (id: any) => {
    form.setFieldValue('id', id);
    setOpenedDeleteDialog(true);
  }


  function deleteSelectedInvoice() {
    setButtonLoading(true);
    http.delete('invoice/' + form.values.id , {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then((response) => {
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      window.location.reload();
      setNotification('Invoice deleted successfully!');
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    })
  }
  

  function printInvoice(id: any) {
    window.open(`/invoice/${id}`, '_blank', 'noopener,noreferrer')
  }

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Sales' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  return (
    <>
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Sales Invoices List</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={handleClick} color="green" className="mt-15" mr={10}>Create Sale Invoice</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Invoice..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div> 
      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        records={data}
        minHeight={90}
        fetching={fetching}
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          {
            accessor: 'customer',
            render: ({customer}: any) => (
              <>{customer ? customer.name : ''}</>
            )
          },
          {
            accessor: 'created_at',
            render: ({ created_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{created_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            textAlignment: 'center',
            render: ({ id, name, code, created_at }) => (
              <>
               <Button onClick={() => printInvoice(id)}>
                Print Invoice
              </Button>
              <Button ml={3} color="red" onClick={() => deleteInvoice(id)}>
                Delete
              </Button>
              </>
            ),
            width: 200,
          },
        ]}
      />
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete Invoice?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" ml="sm" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedInvoice()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
      <Pagination total={total} page={current_page} onChange={(e) => getInvoices(e)} className="justify-content-center" mt={15} />
    </>
  );
}