import { useState, useEffect } from 'react';
import {
  createStyles,
  Group,
  Text,
  Menu,
  Select,
  Image,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconLogout, IconSwitchHorizontal, IconChevronDown, IconUser } from '@tabler/icons';
import { useCookies } from 'react-cookie';
import http from '../../axios/index';
import { useNavigate } from 'react-router-dom';
import Logo from '../../asset/images/craftconcept-logo-final.png';
import Logo1 from '../../asset/images/b_u.png';

const useStyles = createStyles((theme) => ({
  header: {
    paddingTop: theme.spacing.sm,
    borderBottom: `1px solid ${
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background
    }`,
  },
  user: {
    color: theme.white,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',
    '&:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.1
      ),
    },
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },
  userActive: {
    backgroundColor: theme.fn.lighten(
      theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
      0.1
    ),
  },
}));

export default function Header() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'user']);
  const user_id = cookies.user.id;
  const company_id = cookies.user.company_id;
  const [value, setValue] = useState<string | null>(company_id);
  const [companiesData, setCompaniesData] = useState<Array<{ value: string; label: string }>>([]);

  useEffect(() => {
    if (cookies.user.role === 'Super Admin') {
      getCompanies();
    }
  }, []);

  function getCompanies() {
    http.get('all-active-companies', {
      headers: { Authorization: 'Bearer ' + cookies.token },
    }).then((response) => {
      response.data.data.map((item: any) =>
        setCompaniesData((curData) => [...curData, { value: item.id, label: item.name }])
      );
    });
  }

  function changeCompany(companyId: string | null) {
    setValue(companyId);
    http
      .post(
        'change-company',
        { user_id: user_id, company_id: companyId },
        { headers: { Authorization: 'Bearer ' + cookies.token } }
      )
      .then((response) => {
        removeCookie('user');
        setCookie('user', JSON.stringify(response.data));
      });
  }

  function logout() {
    http
      .get('logout', {
        headers: { Authorization: 'Bearer ' + cookies.token },
      })
      .then(() => {
        removeCookie('token');
        removeCookie('user');
      });
  }

  function changeAccount() {
    navigate('/profile');
  }

  function renderImage() {
    if (cookies.user.image) {
      return (
        <Image
          radius="lg"
          src={`${process.env.REACT_APP_WEB_URL}${cookies.user.image}`}
          alt={cookies.user.username}
          height={25}
          width={25}
        />
      );
    } else {
      return <IconUser size={15} />;
    }
  }

  return (
    <div className={classes.header} style={{ borderBottom: 'none', width: '100%', paddingTop: '0px' }}>
      <div className="header-css">
        <Group position="apart">
          {/* Navigate to the homepage when clicking the logo */}
            <Image
            src={cookies.user.email === "bilal@farmhouse.com" ? Logo1 : Logo}
            width={150}
            className="main-logo"
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}
          />
          <div>
            {cookies.user.role === 'Super Admin' && (
              <Select
                placeholder="Select Company"
                data={companiesData}
                value={value}
                onChange={(e) => changeCompany(e)}
                nothingFound="No active company"
                className="desktop-view"
                mr="sm"
              />
            )}
            <Menu
              width={260}
              position="bottom-end"
              transition="pop-top-right"
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
            >
              <Menu.Target>
                <UnstyledButton>
                  <Group spacing={7}>
                    {renderImage()}
                    <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                      {cookies.user.username}
                    </Text>
                    <IconChevronDown size={12} stroke={1.5} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item icon={<IconSwitchHorizontal size={14} stroke={1.5} />} onClick={changeAccount}>
                  Profile
                </Menu.Item>
                <Menu.Item icon={<IconLogout size={14} stroke={1.5} />} onClick={logout}>
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Group>
      </div>
    </div>
  );
}
