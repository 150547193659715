import React, { useState, useEffect } from 'react';
import { DataTable } from 'mantine-datatable';
import { Button, FileInput, NumberInput, Pagination, Modal, TextInput, Select, Notification, Image } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { IconSearch, IconCheck, IconX } from '@tabler/icons';
import { useCookies } from 'react-cookie';
import { useForm } from '@mantine/form';
import http from '../axios/index';
import ErrorPage from "./errors/401";
import Moment from 'react-moment';
import moment from 'moment';

export default function Employees() {

  const [cookies] = useCookies(['token', 'user', 'abilities']);

  const[search, setSearch] = useState("");

  // Image File
  const [file, setFile] = useState<File | null>(null);

  // Fetching
  const [fetching, setFetching] = useState(false);

  // Buttons Loading
  const [buttonLoading, setButtonLoading] = useState(false);

  // Dialogs
  const [openedDialog, setOpenedDialog] = useState(false);
  const [openedDeleteDialog, setOpenedDeleteDialog] = useState(false);

  // Dialog Title and Button
  const [title, setTitle] = useState("")
  const [button, setButton] = useState("")

  // Employees Data
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current_page, setCurrentPage] = useState(0);

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationType, setNotificationType] = useState(true);
  
  // Check Load Data
  const [loadData, setLoadData] = useState(false);

    // Departments with label and value
  const [departmentsData, setDepartmentsData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

    // Designations with label and value
  const [designationsData, setDesignationsData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // Branches with label and value
  const [branchesData, setBranchesData] = useState<
    Array<{
        value: string,
        label: string
    }>
  >([]);

  // On Page Load
  useEffect(()=>{
    getEmployees(1);
    if(!loadData) {
      getDepartments();
      getDesignations();
      getBranches();
      setLoadData(true);
    }
  },[search, loadData]);

  // Employees form
  const form = useForm({
    initialValues: {
      id: '',
      uuid: '',
      name: '',
      email: '',
      phone: '',
      address: '',
      cnic: 0,
      date_of_birth: new Date(),
      date_of_joining: new Date(),
      department_id: '',
      position_id: '',
      branch_id: '',
      status: '1',
    },

    // Form Validations
    validate: {
      name: (value) => (value.length < 2 ? 'Employee name must have at least 2 letters' : null),
      phone: (value) => (value.length < 11 ? 'Phone number must have at least 11 letters' : null),
      cnic: (value) => (value.toString().length < 13 ? 'Phone number must have at least 13 letters' : null),
      date_of_birth: (value) => (value ? null : 'Date of Birth field required'),
      date_of_joining: (value) => (value ? null : 'Date of Joining field required'),
    },
  });

  // Functions

  // Reset Form
  function resetForm() {
    form.reset();
    form.values.id = '';
    form.values.uuid = '';
    form.values.name = '';
    form.values.email = '';
    form.values.phone = '';
    form.values.address = '';
    form.values.cnic = 0;
    form.values.date_of_birth = new Date();
    form.values.date_of_joining = new Date();
    form.values.department_id = '';
    form.values.position_id = '';
    form.values.branch_id = '';
    form.values.status = '1';
  }

  // Get Employees
  function getEmployees(page: any) {
    setFetching(true);
    http.get(`employee/?page=${page}&search=${search}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      setData(response.data.data.data);
      setTotal(response.data.data.last_page);
      setCurrentPage(response.data.data.current_page);
      setFetching(false);
    });
  }

  // Get Branches
  function getBranches() {
    http.get(`company-branches/${cookies.user.company_id}`, {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setBranchesData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  // Get Departments
  function getDepartments() {
    http.get('department', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setDepartmentsData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  // Get Designations
  function getDesignations() {
    http.get('position', {
      headers: {'Authorization': 'Bearer '+cookies.token}
    })
    .then(function (response) {
      response.data.data.map((item: any) => setDesignationsData((curData) => [...curData, { value: item.id, label: item.name }]))
    });
  }

  // Create Employee Function
  const createEmployee = () => {
    resetForm();
    setTitle('Create New Employee!');
    setButton('Save');
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Add Employee
  function addEmployee(file: any) {
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("name", form.values.name);
    formData.append("email", form.values.email);
    formData.append("phone", form.values.phone);
    formData.append("address", form.values.address);
    formData.append("cnic", form.values.cnic.toString());
    formData.append("date_of_birth", moment(form.values.date_of_birth).format('YYYY-M-D'));
    formData.append("date_of_joining", moment(form.values.date_of_joining).format('YYYY-M-D'));
    formData.append("department_id", form.values.department_id);
    formData.append("position_id", form.values.position_id);
    formData.append("branch_id", form.values.branch_id);
    formData.append("company_id", cookies.user.company_id);
    formData.append("status", form.values.status);
    formData.append("image", file);
    http.post('employee', formData, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      getEmployees(1);
      setButtonLoading(false);
      setOpenedDialog(false);
      setNotificationType(true);
      setNotification('Employee created successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.branch_id) {
          form.setFieldError('branch_id', error.response.data.errors.branch_id.shift());
        }
        if (error.response.data.errors.department_id) {
          form.setFieldError('department_id', error.response.data.errors.department_id.shift());
        }
        if (error.response.data.errors.position_id) {
          form.setFieldError('position_id', error.response.data.errors.position_id.shift());
        }
        if (error.response.data.errors.cnic) {
          form.setFieldError('cnic', error.response.data.errors.cnic.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Edit Employee Function
  const editEmployee = ({id, uuid, name, email, phone, address, cnic, date_of_birth, date_of_joining, department_id, position_id, branch_id, status}: any) => {
    setTitle('Edit Employee!');
    setButton('Update');
    resetForm();
    form.setFieldValue('id', id);
    form.setFieldValue('uuid', uuid);
    form.setFieldValue('name', name);
    form.setFieldValue('email', email);
    form.setFieldValue('phone', phone);
    form.setFieldValue('address', address);
    form.setFieldValue('cnic', parseInt(cnic));
    form.setFieldValue('date_of_birth', new Date(date_of_birth));
    form.setFieldValue('date_of_joining', new Date(date_of_joining));
    form.setFieldValue('department_id', department_id);
    form.setFieldValue('position_id', position_id);
    form.setFieldValue('branch_id', branch_id);
    form.setFieldValue('status', (status ? '1': '0'));
    setShowNotification(false);
    setOpenedDialog(true);
  }

  // Update Employee
  function updateEmployee(file: any) {
    setButtonLoading(true);
    const formData = new FormData();
    formData.append("id", form.values.id);
    formData.append("uuid", form.values.uuid);
    formData.append("name", form.values.name);
    formData.append("email", form.values.email);
    formData.append("phone", form.values.phone);
    formData.append("address", form.values.address);
    formData.append("cnic", form.values.cnic.toString());
    formData.append("date_of_birth", moment(form.values.date_of_birth).format('YYYY-M-D'));
    formData.append("date_of_joining", moment(form.values.date_of_joining).format('YYYY-M-D'));
    formData.append("department_id", form.values.department_id);
    formData.append("position_id", form.values.position_id);
    formData.append("branch_id", form.values.branch_id);
    formData.append("company_id", cookies.user.company_id);
    formData.append("status", form.values.status);
    formData.append("image", file);
    http.post('update-employee', formData, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDialog(false);
      getEmployees(1);
      setNotificationType(true);
      setNotification('Employee updated successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.response.data.errors) {
        if (error.response.data.errors.branch_id) {
          form.setFieldError('branch_id', error.response.data.errors.branch_id.shift());
        }
        if (error.response.data.errors.department_id) {
          form.setFieldError('department_id', error.response.data.errors.department_id.shift());
        }
        if (error.response.data.errors.position_id) {
          form.setFieldError('position_id', error.response.data.errors.position_id.shift());
        }
        if (error.response.data.errors.cnic) {
          form.setFieldError('cnic', error.response.data.errors.cnic.shift());
        }
      } else {
        setNotificationType(false);
        setNotification(error.response.data.message);
        setShowNotification(true);
        setTimeout(function() { setShowNotification(false); }, 5000);
      }
    }) 
  }

  // Select Employee to delete
  const deleteEmployee = ({uuid}: any) => {
    form.values.uuid = uuid;
    setOpenedDeleteDialog(true);
  }

  // Delete Employee
  function deleteSelectedEmployee() {
    setButtonLoading(true);
    http.delete('employee/' + form.values.uuid, {
      headers: { Authorization: 'Bearer '+cookies.token }
    })
    .then((response) => {
      resetForm();
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
      getEmployees(1);
      setNotificationType(true);
      setNotification('Employee deleted successfully!');
      setShowNotification(true);
      setTimeout(function() { setShowNotification(false); }, 5000);
    })
    .catch((error) => {
      console.log(error);
      setButtonLoading(false);
      setOpenedDeleteDialog(false);
    }) 
  }

  const abilities = cookies.abilities;
  const ability_exist = abilities.find( (name: any) => name === 'Employees' );

  if (!ability_exist) {
    return (
      <>
        <ErrorPage />
      </>
    );
  }

  function renderNotification() {
    if(showNotification) {
      return (
        <Notification icon={notificationType ? <IconCheck size={18} /> : <IconX size={18} />} color={notificationType ? "teal" : "red"} mt={10}>
          {notification}
        </Notification>
      );
    }
     else {
      return (
        <>
        </>
      );
     }
  }

  return (
    <>
      {renderNotification()}
      <div className="title-section">
        <h2 className="d-inline-block mr-10">Employees</h2>
        <div className="d-inline-block float-right-md mb-15">
          <Button onClick={() => createEmployee()} color="green" className="mt-15" mr={10}>Create New Employee</Button>
          <TextInput icon={<IconSearch />} placeholder="Search Employee..." className="d-inline-block mt-15" style={{width: 200}} value={search} onChange={(e) => setSearch(e.target.value)} withAsterisk />
        </div>
      </div>

      <DataTable
        withBorder
        borderRadius="sm"
        withColumnBorders
        striped
        highlightOnHover
        // provide data
        records={data}
        minHeight={100}
        fetching={fetching}
        // define columns
        columns={[
          {
            accessor: 'id',
            title: 'ID',
            textAlignment: 'center',
            width: 15,
          },
          { accessor: 'name' },
          {
            accessor: 'image',
            render: ({ image, name  }) => (
              <Image
                radius="md"
                src={`${process.env.REACT_APP_WEB_URL}${image ? image : 'assets/images/company-placeholder.jpg'}`}
                alt={name}
                height={75}
                width={75}
              />
            ),
          },
          {
            accessor: 'branch',
            render: ({ branch  }: any) => (
              <>{branch.name}</>
            )
          },
          {
            accessor: 'department',
            render: ({ department  }: any) => (
              <>{department.name}</>
            )
          },
          {
            accessor: 'designation',
            render: ({ position  }: any) => (
              <>{position.name}</>
            )
          },
          {
            accessor: 'status',
            render: ({ status  }) => (
              <>{status === 1 ? 'Enable' : 'Disable'}</>
            )
          },
          {
            accessor: 'last updated',
            render: ({ updated_at  }) => (
              <Moment format="YYYY-MM-DD HH:mm" >{updated_at}</Moment>
            )
          },
          {
            accessor: 'actions',
            render: ({ id, uuid, name, email, phone, address, cnic, date_of_birth, date_of_joining, department_id, position_id, branch_id, status }) => (
              <>
                <Button onClick={() => editEmployee({id, uuid, name, email, phone, address, date_of_birth, date_of_joining, cnic, department_id, position_id, branch_id, status})}>
                  Edit
                </Button>
                <Button ml={10} color="red" onClick={() => deleteEmployee({uuid})}>
                  Delete
                </Button>
              </>
            ),
            textAlignment: 'center',
            width: 200,
          },
        ]}
      />

      <Modal overflow="outside"
        opened={openedDialog}
        onClose={() => setOpenedDialog(false)}
        title={title}
      >
        <form onSubmit={form.onSubmit((values) =>  button === 'Save' ? addEmployee(file) : updateEmployee(file))}>
          {renderNotification()}
          <TextInput mt="sm" label="Name" placeholder="Enter Name" withAsterisk {...form.getInputProps('name')} />
          <TextInput mt="sm" label="Email" placeholder="Enter Email" {...form.getInputProps('email')} />
          <TextInput mt="sm" placeholder="Enter Phone" label="Phone" {...form.getInputProps('phone')} />
          <TextInput mt="sm" label="Address" placeholder="Enter address" {...form.getInputProps('address')} />
          <NumberInput mt="sm" placeholder="Enter CNIC" label="CNIC" hideControls {...form.getInputProps('cnic')}  />          
          <DatePicker placeholder="Select Date" label="Date of Birth" mt="sm" withAsterisk {...form.getInputProps('date_of_birth')}  />
          <DatePicker placeholder="Select Date" label="Date of Joining" mt="sm" withAsterisk {...form.getInputProps('date_of_joining')}  />
          <FileInput
            mt="sm"
            label="Employee Image"
            accept="image/png,image/jpeg"
            clearable={true}
            value={file}
            onChange={setFile}
          />
          <Select
            mt={12}
            label="Branch"
            placeholder="Select Branch"
            data={branchesData}
            {...form.getInputProps('branch_id')}
          />
          <Select
            mt={12}
            label="Department"
            placeholder="Select Department"
            data={departmentsData}
            {...form.getInputProps('department_id')}
          />
          <Select
            mt={12}
            label="Designation"
            placeholder="Select Designation"
            data={designationsData}
            {...form.getInputProps('position_id')}
          />
          <Select
            mt={12}
            label="Status"
            placeholder="Select Status"
            data={[
              { value: '1', label: 'Enable' },
              { value: '0', label: 'Disable' },
            ]}
            {...form.getInputProps('status')}
          />
          <div className="text-right">
            <Button color="red" type="button" mt="sm" onClick={() => setOpenedDialog(false)}>
              Cancel
            </Button>
            <Button color="green" type="submit" ml="sm" loading={buttonLoading}>
              {button}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        opened={openedDeleteDialog}
        onClose={() => setOpenedDeleteDialog(false)}
        title="Confirm!"
      >
        <form>
          <h3>Are You Sure You Want To Delete?</h3>
          <div className="text-right mt-15">
            <Button color="red" type="button" onClick={() => setOpenedDeleteDialog(false)}>
              No
            </Button>
            <Button color="green" type="button" ml="sm" onClick={() => deleteSelectedEmployee()} loading={buttonLoading}>
              Yes
            </Button>
          </div>
        </form>
      </Modal>
    
      <Pagination total={total} page={current_page} onChange={(e) => getEmployees(e)} className="justify-content-center" mt={15} />
    </>
  );
}